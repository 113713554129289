import {
    LocauxCommerciauxIcon,
    TerrainsIcon,
    BureauxIcon,
    EntrepotsIcon,
    FondsDeCommerceIcon,
    PopupStoresIcon,
    MursIcon
} from "../icons"

export default [
    {
        prospection_type: 1,
        type_filter: 31,
        label: "Locaux commerciaux",
        icon: (height, width, marginRight, color) => (
            <LocauxCommerciauxIcon
                color={color || "url(#gradient2)"}
                width={width || 40}
                height={height || 40}
                style={{ marginRight: marginRight || 2 }}
            />
        )
    },
    {
        prospection_type: 2,
        type_filter: 31,
        label: "Locaux commerciaux",
        icon: (height, width, marginRight, color) => (
            <LocauxCommerciauxIcon
                color={color || "url(#gradient2)"}
                width={width || 40}
                height={height || 40}
                style={{ marginRight: marginRight || 2 }}
            />
        ),
        hidden: true
    },
    {
        prospection_type: 9,
        type_filter: 37,
        label: "Fonds de commerce",
        icon: (height, width, marginRight, color) => (
            <FondsDeCommerceIcon
                color={color || "url(#gradient2)"}
                width={width || 40}
                height={height || 40}
                style={{ marginRight: marginRight || 2 }}
            />
        )
    },
    {
        prospection_type: 3,
        type_filter: 32,
        label: "Pop-up store",
        icon: (height, width, marginRight, color) => (
            <PopupStoresIcon
                color={color || "url(#gradient2)"}
                width={width || 40}
                height={height || 40}
                style={{ marginRight: marginRight || 2 }}
            />
        )
    },
    {
        prospection_type: 5,
        type_filter: 34,
        label: "Bureaux",
        icon: (height, width, marginRight, color) => (
            <BureauxIcon
                color={color || "url(#gradient2)"}
                width={width || 40}
                height={height || 40}
                style={{ marginRight: marginRight || 2 }}
            />
        )
    },
    {
        prospection_type: 7,
        type_filter: 35,
        label: "Entrepôts / Activités",
        icon: (height, width, marginRight, color) => (
            <EntrepotsIcon
                color={color || "url(#gradient2)"}
                width={width || 40}
                height={height || 40}
                style={{ marginRight: marginRight || 2 }}
            />
        )
    },
    {
        prospection_type: 4,
        type_filter: 33,
        label: "Terrains",
        icon: (height, width, marginRight, color) => (
            <TerrainsIcon
                color={color || "url(#gradient2)"}
                width={width || 40}
                height={height || 40}
                style={{ marginRight: marginRight || 2 }}
            />
        )
    },
    {
        prospection_type: 8,
        type_filter: 36,
        label: "Dossiers d‘investissement",
        icon: (height, width, marginRight, color) => (
            <MursIcon
                color={color || "url(#gradient2)"}
                width={width || 40}
                height={height || 40}
                style={{ marginRight: marginRight || 2 }}
            />
        )
    }
]