import React from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { generateObfuscatedLink } from '../../helpers/SEO'
import { CDN_DOMAIN } from '../../config/cdn'
import { LinkedInEmptyIcon, InstagramIcon } from '../../icons'

const FooterContainer = styled.footer`
  max-width: 1260px;
  margin: 64px auto 0;
  padding: 40px 20px 0; // Ajout de padding horizontal
  background: transparent;

  @media (max-width: 768px) {
    background: #FBFFFF;
    box-shadow: 0px -8px 12px 0px rgba(167, 176, 192, 0.32);
  }
`

const FooterContent = styled.div`
  display: flex;
  flex-direction: column; // Changement pour column sur mobile
  justify-content: space-between;

  @media (min-width: 768px) {
    flex-direction: row; // Retour à row sur desktop
  }
`

const LogoColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; // Centré sur mobile
  width: 100%;
  margin-bottom: 30px; // Espace entre le logo et les menus sur mobile

  @media (min-width: 768px) {
    align-items: flex-start;
    width: 30%;
    margin-bottom: 0;
  }
`

const Logo = styled.img`
  width: 200px;
  margin-bottom: 20px;
`

const SocialNetwork = styled.div`
  margin-top: 0px;
  display: flex;
  gap: 10px;
`

const SocialNetworkButton = styled.div`
  background-color: #ECFFFF;
  border-radius: 6px;
  padding: 10px;
  display: inline-block;
  transition: all 0.3s ease;

  svg {
    color: var(--Brand-primary, #0CB2AD);
    transition: color 0.3s ease;
  }

  &:hover {
    background-color: var(--Brand-primary, #0CB2AD);
    transform: translateY(-3px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    svg {
      color: #ECFFFF;
    }
  }
`

const MenuColumns = styled.div`
  display: flex;
  flex-direction: column; // Empilé sur mobile
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    width: 65%;
  }
`

const MenuColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; // Centré sur mobile
  margin-bottom: 30px; // Espace entre les colonnes sur mobile

  @media (min-width: 768px) {
    align-items: flex-start;
    margin-bottom: 0;
  }
`

const MenuTitle = styled.div`
  text-transform: uppercase;
  color: var(--Brand-primary);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 25px;
`

const MenuItem = styled.div`
  margin-bottom: 15px;
`

const MenuLink = styled.a`
  color: var(--Neutral-700);
  font-size: 16px;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: var(--Brand-primary);
  }
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #CCE0FE;
  margin-top: 40px;
  margin-bottom: 70px;
`

const DividerContainer = styled.div`
  padding: 0px;
`

const UECFooter = ({ obfuscateLinks, config }) => {
  return (
    <FooterContainer>
      <FooterContent>
        <LogoColumn>
          <Logo
            src={`${CDN_DOMAIN}/static/logo_green.png`}
            alt="Unemplacement.com"
            title="Unemplacement.com"
            className="mb-0"
          />
          <div
            className="text-brand-primary font-montserrat text-[13px] font-normal leading-tight w-[170px] -mt-4"
          >
            L'immobilier d'entreprise Off-market
          </div>
        </LogoColumn>
        <MenuColumns>
          <MenuColumn>
            <MenuTitle>Conditions</MenuTitle>
            <MenuItem>
              {!obfuscateLinks ? (
                <Link href="/mentions-legales" passHref>
                  <MenuLink>Mentions légales</MenuLink>
                </Link>
              ) : (
                generateObfuscatedLink("/mentions-legales", "Mentions légales")
              )}
            </MenuItem>
            <MenuItem>
              {!obfuscateLinks ? (
                <Link href="/protection-des-donnees" passHref>
                  <MenuLink>Protection des données</MenuLink>
                </Link>
              ) : (
                generateObfuscatedLink("/protection-des-donnees", "Protection des données")
              )}
            </MenuItem>
            <MenuItem>
              {!obfuscateLinks ? (
                <Link href="/cgu" passHref>
                  <MenuLink>CGU</MenuLink>
                </Link>
              ) : (
                generateObfuscatedLink("/cgu", "CGU")
              )}
            </MenuItem>
          </MenuColumn>
          <MenuColumn>
            <MenuTitle>A propos</MenuTitle>
            <MenuItem>
              {!obfuscateLinks ? (
                <Link href="/qui-sommes-nous" passHref>
                  <MenuLink>Qui sommes-nous ?</MenuLink>
                </Link>
              ) : (
                generateObfuscatedLink("/qui-sommes-nous", "Qui sommes-nous ?")
              )}
            </MenuItem>
            <MenuItem>
              <MenuLink href="https://blog.unemplacement.com/">
                Actualités
              </MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink href="/guide">Guide</MenuLink>
            </MenuItem>
          </MenuColumn>
          <MenuColumn>
            <MenuTitle>Contactez-nous</MenuTitle>
            <MenuItem>
              <MenuLink href="tel:+33622497152">
                06 22 49 71 52
              </MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink href="mailto:contact@unemplacement.com?subject=UnEmplacement.com demande d'information">
                contact@unemplacement.com
              </MenuLink>
            </MenuItem>
            <MenuItem>
              <SocialNetwork>
                <a
                  href="https://www.linkedin.com/company/unemplacement-com"
                    target="_blank"
                    rel="nofollow noopener"
                >
                    <SocialNetworkButton>
                        <LinkedInEmptyIcon
                            width={24}
                            height={24}
                        />
                    </SocialNetworkButton>
                </a>
                <a
                  href="https://www.instagram.com/unemplacement.com_/"
                    target="_blank"
                    rel="nofollow noopener"
                >
                    <SocialNetworkButton>
                        <InstagramIcon
                            width={24}
                            height={24}
                        />
                    </SocialNetworkButton>
                </a>
            </SocialNetwork>
            </MenuItem>
          </MenuColumn>
        </MenuColumns>
      </FooterContent>
      <DividerContainer>
        <Divider />
      </DividerContainer>
      <div className="text-[#888] text-[13px] font-medium leading-normal pb-[70px]">
        © 2024 - Unemplacement.com : Tous droits réservés
      </div>
    </FooterContainer>
  )
}

export default UECFooter