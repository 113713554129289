import { useState, useEffect } from 'react';

export const useDevicePixelRatio = () => {
  const [pixelRatio, setPixelRatio] = useState(1);

  useEffect(() => {
    const updatePixelRatio = () => {
      if (typeof window !== 'undefined') {
        setPixelRatio(window.devicePixelRatio || 1);
      }
    };

    // Set initial value
    updatePixelRatio();

    if (typeof window !== 'undefined') {
      // Update on window resize
      window.addEventListener('resize', updatePixelRatio);

      // Clean up
      return () => window.removeEventListener('resize', updatePixelRatio);
    }
  }, []);

  return pixelRatio;
};