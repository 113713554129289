import React, { useEffect, useRef, useCallback, memo, useState, forwardRef } from 'react'
import _ from 'lodash'
import moment from 'moment'
import ReactPlayer from 'react-player'
import styled, { keyframes, css } from 'styled-components'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import TextTruncate from 'react-text-truncate'
import { useSwipeable } from 'react-swipeable'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import axios from '../../config/axios'
import getConfig from 'next/config'
import { modelTransactionsFetch } from '../../models/Transactions'

import types from '../../datas/prospectionMapTypes'
import { CDN_DOMAIN } from '../../config/cdn'

import { PinIcon, HandshakeIcon } from '../../icons'
import removeDiacritics from '../../helpers/Diacritics'

import UECFooter from './UECFooter'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'
import { useDevicePixelRatio } from '../../hooks/useDevicePixelRatio'

import {
  generateObfuscatedDynamicLinkString,
  enableObfuscatedLink,
  enableDynamicPublicPagesLink,
  generateObfuscatedLink
} from '../../helpers/SEO'

const PlayIcon = () => (
  <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="42" cy="42" r="42" fill="url(#paint0_linear_175_3526)"/>
    <path d="M59.75 39.6817C61.4167 40.644 61.4167 43.0496 59.75 44.0119L31.25 60.4663C29.5833 61.4286 27.5 60.2258 27.5 58.3013L27.5 25.3923C27.5 23.4678 29.5833 22.265 31.25 23.2272L59.75 39.6817Z" stroke="white" strokeWidth="7"/>
    <defs>
      <linearGradient id="paint0_linear_175_3526" x1="2.60053e-06" y1="-2.52" x2="77.7" y2="89.04" gradientUnits="userSpaceOnUse">
        <stop stopColor="#22DAD1"/>
        <stop offset="1" stopColor="#3AE7B1"/>
      </linearGradient>
    </defs>
  </svg>
);

const getId = str => {
  return removeDiacritics(_.toLower(str))
    .replace(" - ", "-")
    .replace(/'/g, "-")
    .replace(/\(/g, "")
    .replace(/\)/g, "")
    .replace(", ", "-")
    .replace(/[^a-zA-Z0-9\- ]/g, "")
    .trim()
    .replace(/ /g, "-")
    .replace(/\-\-/g, "-")
}

const calculateTimeSince = timestamp => {
  const now = new Date().getTime()
  const diff = Math.floor((now - timestamp) / 1000)

  if (diff === 0) {
    return "un instant"
  } else if (diff === 1) {
    return "1s"
  } else if (diff < 60) {
    return `${diff}s`
  } else if (diff < 120) {
    return "1m"
  } else if (diff < 3600) {
    const minutes = Math.floor(diff / 60)
    return `${minutes}m`
  } else if (diff < 7200) {
    return "1h"
  } else {
    const hours = Math.floor(diff / 3600)
    return `${hours}h`
  }
}

const bgsMatching = {
  1: `${CDN_DOMAIN}/static/v2/bgs-shadow/bg-local-commercial-shadow.png`,
  9: `${CDN_DOMAIN}/static/v2/bgs-shadow/bg-fonds-commerce-shadow.png`,
  3: `${CDN_DOMAIN}/static/v2/bgs-shadow/bg-pop-up-store-shadow.png`,
  5: `${CDN_DOMAIN}/static/v2/bgs-shadow/bg-bureaux-shadow.png`,
  7: `${CDN_DOMAIN}/static/v2/bgs-shadow/bg-entrepot-shadow.png`,
  4: `${CDN_DOMAIN}/static/v2/bgs-shadow/bg-terrain-shadow.png`,
  8: `${CDN_DOMAIN}/static/v2/bgs-shadow/bg-investissement-shadow.png`
}

const ResponsiveTextTruncate = responsiveHOC()(TextTruncate)

const TransactionButton = styled.button`
  background-color: var(--Brand-primary);
  color: var(--color-light-100);
  border: none;
  width: 100%;
  padding: 16px 24px;
  border-radius: 100px;
  cursor: pointer;
  margin-top: 16px;
  font-weight: 500;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background: var(--Brand-primary-dark);
  }
`

const MainContainer = styled.div`
  max-width: 1260px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    min-height: 770px;
  }
`

const BackgroundBlur = styled.div`
  position: absolute;
  top: 0px;
  left: -202px;
  width: 404px;
  height: 404px;
  border-radius: 404px;
  background: var(--color-green-blur);
  filter: blur(23px);
  z-index: 1;

  @media (max-width: 768px) {
    display: none;
  }
`

const BackgroundBlurSecond = styled.div`
  position: absolute;
  top: -292px;
  left: -292px;
  width: 584px;
  height: 584px;
  border-radius: 584px;
  background: var(--color-green-blur);
  filter: blur(23px);
  z-index: -1;

  @media (max-width: 768px) {
    display: none;
  }
`

const GreenGradientBackground = styled.div`
  position: absolute;
  top: 35%;
  left: 28%;
  transform: translate(-50%, -50%);
  width: 1264px;
  height: 1157px;
  background-image: url(${CDN_DOMAIN}/static/v2/green-gradient.svg);
  background-repeat: no-repeat;
  z-index: -1;
  pointer-events: none;

  @media (max-width: 768px) {
    display: none;
  }
`

const LeftContainer = styled.div`
  position: absolute;
  left: 20px;
  top: 0px;
  max-width: 660px;
  height: 100%;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    top: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    padding: 20px;
    padding-top: 5px;
    height: auto;
  }
`

const TextWrapper = styled.div`
  max-width: 85%;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`

const PanoramaContainer = styled.div`
  position: relative;
  height: 812px;
  width: 788px;
  margin: 0;
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
  }
`

const PanoramaOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 18px);
  height: calc(100% - 18px);
  z-index: 2;

  @media (max-width: 768px) {
    position: static;
    width: 100%;
    height: auto;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    @media (max-width: 768px) {
      position: relative;
      top: -60px;
      content: ${props => props.mobileImage ? `url(${props.mobileImage})` : 'none'};

      @media only screen and (min-resolution: 1dppx) {
        content: image-set('${props => props.mobileImage ? `${props.mobileImage}` : 'none'}' 1x, '${props => props.mobileImage2x ? `${props.mobileImage2x}` : 'none'}' 2x);
      }

      @media only screen and (min-resolution: 2dppx) {
        content: image-set('${props => props.mobileImage2x ? `${props.mobileImage2x}` : 'none'}' 2x, '${props => props.mobileImage3x ? `${props.mobileImage3x}` : 'none'}' 3x);
      }
    }
  }
`

const ShadowContainer = styled.div`
  margin-top: 20px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 32px;
  padding-bottom: 32px;
  background-color: white;
  border-radius: 24px;
  box-shadow: var(--shadow-200);

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 0;
    border-radius: 24px;
    padding: 10px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 0;
  flex-direction: column;
  position: relative;
`

const Button = styled.button`
  width: 270px;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const FreeServiceArrow = styled.div`
  position: relative;
  left: -155px;
  width: 270px;
  gap: 10px;

  span {
    color: #373737;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    b {
      font-weight: 600;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    left: 0;

    img {
      content: url(${CDN_DOMAIN}/static/v2/free-service-arrow-mobile.svg);
    }

    span {
      position: relative;
      top: 16px;
    }
  }
`

const TrustedByTitle = styled.div`
  color: #000;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const ScrollingContainer = styled.div`
  overflow: hidden;
  margin-top: 36px;
  position: relative;
  display: flex;
  
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100px;
    height: 100%;
    z-index: 2;
  }

  &::before {
    left: 0;
    background: linear-gradient(to right, var(--color-light-100) 0%, rgba(255, 255, 255, 0) 100%);
  }

  &::after {
    right: 0;
    background: linear-gradient(to left, var(--color-light-100) 0%, rgba(255, 255, 255, 0) 100%);
  }
`

const ScrollingContent = styled.div`
  display: flex;
  animation: scroll 120s linear infinite;

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }

  &:hover {
    animation-play-state: paused;
  }
`

const TrustedByBlock = styled.div`
  width: ${props => props.width}px;
  margin-left: 16px;
  margin-right: 16px;
  white-space: nowrap;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    border-radius: 26.689px;
    border: 0.741px solid var(--Neutral-100, #F2F7F9);
    background: var(--color-light-100, #FFF);
    box-shadow: 0px 5.931px 8.896px 0px rgba(167, 176, 192, 0.32);
    margin-bottom: 16px;
    margin-right: 7px;
    padding: 10px;
    height: 74px;
  }
`

const TrustedByTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TrustedByBelowContainer = styled.div`
  z-index: 2;
  max-width: 1260px;
  width: calc(100% - 40px);
  margin: 32px 20px 0;
  padding: 32px;
  border-radius: 32px;
  background: var(--color-light-100, #FFF);
  box-shadow: var(--shadow-300);

  @media (max-width: 768px) {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    box-shadow: none;
    padding: 0;
    padding-top: 16px;
    padding-bottom: 16px;
    background: transparent;
  }
`;

const DesktopOnly = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileOnly = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;

const MemoizedTrustedByContainer = memo(() => {
  const trustedCompanies = [
    {
      name: 'Starbucks',
      logo: '/static/v2/logos/starbucks.svg',
      hmax: 61,
      width: 232,
      order: 1
    },
    {
      name: 'Monoprix',
      logo: '/static/v2/logos/monoprix.svg',
      hmax: 39,
      width: 169,
      order: 2
    },
    {
      name: 'Action',
      logo: '/static/v2/logos/action.svg',
      hmax: 33,
      width: 176,
      order: 3
    },
    {
      name: 'Five Guys',
      logo: '/static/v2/logos/five_guys.svg',
      hmax: 47,
      width: 168,
      order: 4
    },
    {
      name: 'Cultura',
      logo: '/static/v2/logos/cultura.svg',
      hmax: 61,
      width: 152,
      order: 5
    },
    {
      name: 'Norauto',
      logo: '/static/v2/logos/norauto.svg',
      hmax: 45,
      width: 164,
      order: 6
    },
    {
      name: 'Häagen-Dazs',
      logo: '/static/v2/logos/haagen_dazs.svg',
      hmax: 77,
      width: 135,
      order: 7
    }/*,
    {
      name: 'Casino',
      logo: '/static/v2/hmax_61/casino.svg',
      hmax: 61,
      width: 146,
      order: 8
    }*/,
    {
      name: 'PMU',
      logo: '/static/v2/logos/PMU.svg',
      hmax: 49,
      width: 139,
      order: 9
    },
    {
      name: 'Buffalo Grill',
      logo: '/static/v2/logos/buffalo_grill.svg',
      hmax: 45,
      width: 152,
      order: 10
    },
    {
      name: 'Gémo',
      logo: '/static/v2/logos/gemo.svg',
      hmax: 41,
      width: 133,
      order: 11
    },
    {
      name: 'Dominos',
      logo: '/static/v2/logos/dominos.svg',
      hmax: 49,
      width: 216,
      order: 12
    },
    {
      name: 'Carglass',
      logo: '/static/v2/logos/carglass.svg',
      hmax: 51,
      width: 193,
      order: 13
    },
    {
      name: 'Intermarché',
      logo: '/static/v2/logos/intermarche.svg',
      hmax: 33,
      width: 177,
      order: 14
    },
    {
      name: 'Subway',
      logo: '/static/v2/logos/subway.svg',
      hmax: 34,
      width: 168,
      order: 15
    },
    {
      name: 'Afflelou',
      logo: '/static/v2/logos/afflelou.svg',
      hmax: 45,
      width: 208,
      order: 16
    },
    {
      name: 'Basic-Fit',
      logo: '/static/v2/logos/basic_fit.svg',
      hmax: 43,
      width: 152,
      order: 17
    },
    {
      name: 'Auchan',
      logo: '/static/v2/logos/auchan.svg',
      hmax: 33,
      width: 149,
      order: 18
    },
    {
      name: 'Krys',
      logo: '/static/v2/logos/krys.svg',
      hmax: 59,
      width: 132,
      order: 19
    },
    {
      name: 'GiFi',
      logo: '/static/v2/logos/gifi.svg',
      hmax: 91,
      width: 95,
      order: 20
    },
    {
      name: 'Bureau Vallée',
      logo: '/static/v2/logos/bureau_vallee.svg',
      hmax: 61,
      width: 121,
      order: 21
    },
    {
      name: 'Franprix',
      logo: '/static/v2/logos/franprix.svg',
      hmax: 33,
      width: 170,
      order: 22
    },
    {
      name: 'Boulangerie Ange',
      logo: '/static/v2/logos/ange_boulangerie.svg',
      hmax: 83,
      width: 97,
      order: 23
    },
    {
      name: 'Optical Center',
      logo: '/static/v2/logos/optical_center.svg',
      hmax: 61,
      width: 146,
      order: 24
    },
    {
      name: 'Kiloutou',
      logo: '/static/v2/logos/kiloutou.svg',
      hmax: 25,
      width: 188,
      order: 25
    },
    {
      name: 'Adopt‘',
      logo: '/static/v2/logos/adopt.svg',
      hmax: 55,
      width: 173,
      order: 26
    },
    {
      name: 'Ixina',
      logo: '/static/v2/logos/ixina.svg',
      hmax: 31,
      width: 161,
      order: 27
    },
    {
      name: 'Maison Thiriet',
      logo: '/static/v2/logos/maison_thiriet.svg',
      hmax: 71,
      width: 124,
      order: 28
    },
    {
      name: 'Pizza Time',
      logo: '/static/v2/logos/pizza_time.svg',
      hmax: 77,
      width: 103,
      order: 29
    },
    {
      name: 'Animalis',
      logo: '/static/v2/logos/animalis.svg',
      hmax: 33,
      width: 190,
      order: 30
    },
    {
      name: 'Palais des thés',
      logo: '/static/v2/logos/palais_des_thes.svg',
      hmax: 45,
      width: 271,
      order: 31
    },
    {
      name: 'Rentacar',
      logo: '/static/v2/logos/rent_a_car.svg',
      hmax: 49,
      width: 184,
      order: 32
    },
    {
      name: 'O2',
      logo: '/static/v2/logos/O2.svg',
      hmax: 49,
      width: 77,
      order: 33
    },
    {
      name: 'Fitness Park',
      logo: '/static/v2/logos/fitness_park.svg',
      hmax: 41,
      width: 201,
      order: 34
    },
    {
      name: 'Takko Fashion',
      logo: '/static/v2/logos/takko_fashion.svg',
      hmax: 61,
      width: 128,
      order: 35
    },
    {
      name: 'Stokomani',
      logo: '/static/v2/logos/stockomani.svg',
      hmax: 53,
      width: 171,
      order: 36
    },
    {
      name: 'V&B',
      logo: '/static/v2/logos/V&B.svg',
      hmax: 69,
      width: 101,
      order: 37
    },
    {
      name: 'Unilabs',
      logo: '/static/v2/logos/unilabs.svg',
      hmax: 53,
      width: 165,
      order: 38
    },
    {
      name: 'Babilou',
      logo: '/static/v2/logos/babilou.svg',
      hmax: 55,
      width: 148,
      order: 39
    },
    {
      name: 'Vapostore',
      logo: '/static/v2/logos/vapostore.svg',
      hmax: 23,
      width: 220,
      order: 40
    }/*,
    {
      name: 'Boulangerie Sophie Lebreuilly',
      logo: '/static/v2/hmax_33/sophie_lebreuilly.svg',
      hmax: 33,
      width: 34,
      order: 41
    }*/,
    {
      name: 'Intercaves',
      logo: '/static/v2/logos/intercaves.svg',
      hmax: 37,
      width: 239,
      order: 42
    },
    {
      name: 'Cuisine Plus',
      logo: '/static/v2/logos/cuisine_plus.svg',
      hmax: 45,
      width: 184,
      order: 43
    },
    {
      name: 'Easy Cash',
      logo: '/static/v2/logos/easycash.svg',
      hmax: 51,
      width: 178,
      order: 44
    },
    {
      name: 'EVA - Esports Virtual Arenas',
      logo: '/static/v2/logos/eva.svg',
      hmax: 43,
      width: 119,
      order: 45
    },
    {
      name: 'Feuillette',
      logo: '/static/v2/logos/feuillette_boulangerie.svg',
      hmax: 99,
      width: 68,
      order: 46
    },
    {
      name: 'Cavavin',
      logo: '/static/v2/logos/cavavin.svg',
      hmax: 61,
      width: 129,
      order: 47
    },
    {
      name: 'Ninkasi',
      logo: '/static/v2/logos/ninkasi.svg',
      hmax: 51,
      width: 159,
      order: 48
    },
    {
      name: 'ON AIR Fitness',
      logo: '/static/v2/logos/on_air_fitness.svg',
      hmax: 43,
      width: 120,
      order: 49
    },
    {
      name: 'OTIUM Leisure',
      logo: '/static/v2/logos/otium_leisure.svg',
      hmax: 53,
      width: 132,
      order: 50
    },
    {
      name: 'Pascal Coste',
      logo: '/static/v2/logos/pascal_coste.svg',
      hmax: 23,
      width: 206,
      order: 51
    },
    {
      name: 'Popeyes',
      logo: '/static/v2/logos/popeyes.svg',
      hmax: 41,
      width: 160,
      order: 52
    },
    {
      name: 'Primaprix',
      logo: '/static/v2/logos/primaprix.svg',
      hmax: 43,
      width: 153,
      order: 53
    },
    {
      name: 'Rexel',
      logo: '/static/v2/logos/rexel.svg',
      hmax: 45,
      width: 168,
      order: 54
    }/*,
    {
      name: 'Carrément Fleurs',
      logo: '/static/v2/hmax_61/carrement_fleurs.svg',
      hmax: 61,
      width: 65,
      order: 55
    }*/,
    {
      name: 'Mobalpa',
      logo: '/static/v2/hmax_33/mobalpa.svg',
      hmax: 33,
      width: 152,
      order: null
    }
  ]

  const sortedTrustedCompanies = _.sortBy(trustedCompanies, 'order').filter(o => o.order !== null)

  return (
    <div className="flex justify-center">
      <TrustedByBelowContainer>
        <TrustedByTitleRow>
          <div className="flex flex-col w-full md:w-auto">
            <TrustedByTitle>5000+ sociétés nous font confiance</TrustedByTitle>
          </div>
          <DesktopOnly>
            <DesktopVideoDialog />
          </DesktopOnly>
        </TrustedByTitleRow>
        <ScrollingContainer>
          <ScrollingContent>
            {sortedTrustedCompanies.map((company, index) => (
              <TrustedByBlock key={`company-${index}`} width={company.width}>
                <img src={`${CDN_DOMAIN}${company.logo}`} alt={company.name} style={{ width: company.width, height: company.hmax }} />
              </TrustedByBlock>
            ))}
            {sortedTrustedCompanies.map((company, index) => (
              <TrustedByBlock key={`company-repeat-${index}`} width={company.width}>
                <img src={`${CDN_DOMAIN}${company.logo}`} alt={company.name} style={{ width: company.width, height: company.hmax }} />
              </TrustedByBlock>
            ))}
          </ScrollingContent>
        </ScrollingContainer>
        <MobileOnly>
          <MobileVideoDialog />
        </MobileOnly>
      </TrustedByBelowContainer>
    </div>
  );
})

const BelowContainer = styled.div`
  width: calc(100% - 40px);
  max-width: 1260px;
  margin: 64px auto 0;
  margin-top: 15px;
  padding: 32px;
  border-radius: 32px;
  background: var(--color-light-100, #FFF);
  box-shadow: var(--shadow-300);
  margin-left: 20px;
  margin-right: 20px;

  @media (max-width: 768px) {
    width: 100%;
    box-shadow: none;
    margin: 32px auto 0;
    margin-top: 0;
    margin-bottom: 64px;
    padding: 16px;
    padding-left: 0;
    padding-right: 0;
    background: transparent;
    margin-left: 0px;
    margin-right: 0px;
  }
`

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`

const CapitalButton = styled.button`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: var(--Neutral-100, #F2F7F9);
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: var(--Neutral-700, #2A3342);
  transition: background-color 0.3s ease;

  &:hover {
    background: var(--Neutral-200, #E3EDF3);
  }

  @media (max-width: 768px) {
    margin-top: 36px;
  }
`

const ButtonLogo = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
`

const ImageGrid = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;

  div.grid-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 768px) {
    a {
      margin-left: 20px;
    }

    &:last-child {
      margin-left: 0;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      width: 100px;
      height: 100%;
      z-index: 2;
    }

    &::before {
      left: 0;
      background: linear-gradient(to right, var(--color-light-100) 0%, rgba(255, 255, 255, 0) 100%);
    }

    &::after {
      right: 0;
      background: linear-gradient(to left, var(--color-light-100) 0%, rgba(255, 255, 255, 0) 100%);
    }

    div.grid-container {
      overflow-x: auto;
      justify-content: flex-start;
      padding-bottom: 16px;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
`

const ImageBlock = styled.div`
  display: flex;
  width: 160px;
  height: 80px;
  padding: 20px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  border: 1px solid var(--Neutral-300, #E6EEF2);
  background: var(--color-light-100, #FFF);

  @media (max-width: 768px) {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
`

const TransitionImageComponent = forwardRef(({ id, currentSrc, nextSrc, alt, className, draggable, isTransitioning, ...props }, ref) => {
  const pixelRatio = useDevicePixelRatio();

  // Function to get image sources for different pixel ratios
  const getImageSources = (baseSrc) => {
    if (baseSrc.toLowerCase().endsWith('.svg')) {
      return [baseSrc];
    }

    const parts = baseSrc.split('.');
    const ext = parts.pop();
    const base = parts.join('.');

    return [
      `${base}@3x.${ext}`,
      `${base}@2x.${ext}`,
      baseSrc
    ];
  };

  // Preload images
  useEffect(() => {
    const preloadImage = (src) => {
      const img = new Image();
      img.src = src;
    };

    if (currentSrc) {
      getImageSources(currentSrc).forEach(preloadImage);
    }
    if (nextSrc) {
      getImageSources(nextSrc).forEach(preloadImage);
    }
  }, [currentSrc, nextSrc]);

  const getImageSrc = (baseSrc) => {
    if (!baseSrc) {
      return `${CDN_DOMAIN}/static/v2/bgs/bg-white.png`;
    }

    if (baseSrc.toLowerCase().endsWith('.svg')) {
      return baseSrc;
    }

    const parts = baseSrc.split('.');
    const ext = parts.pop();
    const base = parts.join('.');

    if (pixelRatio > 2) {
      return `${base}@3x.${ext} 3x, ${base}@2x.${ext} 2x, ${baseSrc}`;
    } else if (pixelRatio > 1) {
      return `${base}@2x.${ext} 2x, ${baseSrc}`;
    }
    return baseSrc;
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <img
        id={id}
        ref={ref}
        src={currentSrc || `${CDN_DOMAIN}/static/v2/bgs/bg-white.png`}
        srcSet={currentSrc ? getImageSrc(currentSrc) : `${CDN_DOMAIN}/static/v2/bgs/bg-white.png`}
        alt={alt}
        className={`${className} ${isTransitioning ? 'fade-out duration-1000' : ''}`}
        draggable={draggable}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          opacity: isTransitioning ? 0 : 1,
        }}
        {...props}
      />
      {nextSrc && (
        <img
          src={nextSrc}
          srcSet={getImageSrc(nextSrc)}
          alt={alt}
          className={`${className} fade-in duration-1000`}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: isTransitioning ? 1 : 0,
          }}
        />
      )}
    </div>
  );
});

const PanoramaComponent = memo(({ selectedType, hoveredType }) => {
  const [activeType, setActiveType] = useState(null);
  const [currentSrc, setCurrentSrc] = useState(null);
  const [nextSrc, setNextSrc] = useState(null);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);

  // Debounce the setActiveType function
  const debouncedSetActiveType = useCallback(
    _.debounce((type) => {
      setActiveType(type);
    }, 100),
    []
  );

  useEffect(() => {
    const newActiveType = hoveredType || selectedType;
    debouncedSetActiveType(newActiveType);
  }, [hoveredType, selectedType, debouncedSetActiveType]);

  useEffect(() => {
    const isDefaultImage = activeType === null;
    const src = isDefaultImage ? `${CDN_DOMAIN}/static/v2/bgs-shadow/bg-default-shadow.png` : bgsMatching[activeType];

    if (src !== currentSrc) {
      setNextSrc(src);
      setIsTransitioning(true);

      const timer = setTimeout(() => {
        setCurrentSrc(src);
        setNextSrc(null);
        setIsTransitioning(false);
      }, 300); // Duration of the fade effect

      return () => clearTimeout(timer);
    }
  }, [activeType, currentSrc]);

  // Add resize event listener
  useEffect(() => {
    const handleResize = _.debounce(() => {
      setWindowWidth(window.innerWidth);
    }, 250);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <PanoramaContainer>
      <PanoramaOverlay
        mobileImage={`${CDN_DOMAIN}/static/v2/paris-panorama-mobile.png`}
        mobileImage2x={`${CDN_DOMAIN}/static/v2/paris-panorama-mobile@2x.png`}
        mobileImage3x={`${CDN_DOMAIN}/static/v2/paris-panorama-mobile@3x.png`}
      >
        {windowWidth > 768 ? (
          <TransitionImageComponent
            currentSrc={currentSrc}
            nextSrc={nextSrc}
            className="w-full h-full object-contain"
            draggable="false"
            isTransitioning={isTransitioning}
          />
        ) : (
          <ImageComponent
            src={`${CDN_DOMAIN}/static/v2/paris-panorama.png`}
            alt="Paris Panorama"
            className="w-full h-full object-contain"
            draggable="false"
          />
        )}
      </PanoramaOverlay>
    </PanoramaContainer>
  );
});

const ImageComponent = forwardRef(({ id, src, alt, className, draggable, ...props }, ref) => {
  const pixelRatio = useDevicePixelRatio();
  
  const getImageSrc = (baseSrc) => {
    if (!baseSrc) {
      return `${CDN_DOMAIN}/static/v2/bgs/bg-white.png`;
    }

    if (baseSrc.toLowerCase().endsWith('.svg')) {
      return baseSrc;
    }

    const parts = baseSrc.split('.');
    const ext = parts.pop();
    const base = parts.join('.');
    
    if (pixelRatio > 2) {
      return `${base}@3x.${ext} 3x, ${base}@2x.${ext} 2x, ${baseSrc}`;
    } else if (pixelRatio > 1) {
      return `${base}@2x.${ext} 2x, ${baseSrc}`;
    }
    return baseSrc;
  };

  return (
    <img
      id={id}
      ref={ref}
      src={src || `${CDN_DOMAIN}/static/v2/bgs/bg-white.png`}
      srcSet={getImageSrc(src)}
      alt={alt}
      className={className}
      draggable={draggable}
      {...props}
    />
  );
});

const BlockImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  transform: ${props => `scale(${props.scale})`};
`

const MainWrapper = styled.div`
  position: relative;
  top: 110px;

  @media (max-width: 1402px) {
    top: 0;
  }
`

const MarketContainer = styled.div`
  max-width: 1260px;
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 500px;
  margin-top: 64px;
  overflow-x: clip;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 20px;
    border-radius: 24px;
    border: 1px solid var(--Neutral-300);
    background: var(--color-light-100);
    box-shadow: 0px 12px 20px 0px rgba(167, 176, 192, 0.24);
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 48px;
    width: calc(100% - 40px);
  }
`

const BackgroundSVG = styled.svg`
  position: relative;
  width: 1210.425px;
  height: 1049.661px;
  flex-shrink: 0;
  right: -10%;

  @media (max-width: 768px) {
    display: none;
  }
`

const DoorSVG = styled(ImageComponent)`
  position: absolute;
  top: 10%;
  right: 13%;
  z-index: 1;
  width: 240px;
  aspect-ratio: 279 / 457;
  opacity: 0;
  ${props => props.isVisible && css`
    animation: ${fadeInScale} 0.3s ease-out forwards;
  `}

  @media (max-width: 768px) {
    position: static;
    width: 100%;
    max-width: 88px;
    margin: 20px auto 0;
  }
`

const TitleBlock = styled.div`
  position: absolute;
  max-width: 570px;
  left: 4%;
  z-index: 2;

  @media (max-width: 768px) {
    position: static;
    max-width: 100%;
    margin-bottom: 20px;

    h2 {
      color: var(--Neutral-900, #001723);
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }

    p {
      color: var(--Neutral-900, #001723);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 20px;
    }
  }
`

const HighlightedText = styled.span`
  color: var(--Brand-primary);
`

const ContainerStyle = styled.div`
  position: relative;
  max-width: 1260px;
  margin: 64px auto 0;
  padding: 32px;
  width: 100%;
  height: 500px;
  margin-top: 64px;
  overflow: hidden;
`

const FranceContainer = styled(ContainerStyle)`
  height: 500px;
  margin-bottom: 150px;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 20px;
    border-radius: 24px;
    border: 1px solid var(--Neutral-300);
    background: var(--color-light-100);
    box-shadow: 0px 12px 20px 0px rgba(167, 176, 192, 0.24);
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 0px;
    width: calc(100% - 40px);
    display: flex;
  }
`

const BackgroundImage = styled(ImageComponent)`
  position: absolute;
  left: 20px;
  top: 0;
  height: 100%;
  width: auto;
  opacity: 0;
  ${props => props.isVisible && css`
    animation: ${fadeInScale} 0.3s ease-out forwards;
  `}

  @media (max-width: 768px) {
    position: static;
    width: 100%;
    max-width: 160px;
    height: auto;
    margin: 20px auto 0;
    order: 2;
  }
`

const TextBlock = styled.div`
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
  max-width: 500px;
  z-index: 2;

  @media (max-width: 768px) {
    position: static;
    transform: none;
    max-width: 100%;
    margin-bottom: 20px;
    order: 1;

    h2 {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 20px;
    }
  }
`

const CommunityWrapper = styled.div`
  position: relative;
  max-width: 1260px;
  margin: 64px auto;

  @media (max-width: 768px) {
    margin: 0;
    height: 750px;
    overflow: hidden;
  }
`

const CommunityContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 100px;
  position: relative;
  margin-left: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 20px;
    border-radius: 24px;
    border: 1px solid var(--Neutral-300);
    background: var(--color-light-100);
    box-shadow: 0px 12px 20px 0px rgba(167, 176, 192, 0.24);
    width: calc(100% - 40px);
    overflow: hidden;
    position: relative;
    z-index: 2;
    justify-content: center;
    margin-left: 20px;
    margin-right: 20px;
    top: 50%;
    transform: translateY(-50%);
    margin-bottom: 0;
  }
`

const OnlineUsersContainer = styled.div`
  z-index: 1;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  width: 600px;
  height: 520px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;

  @media (max-width: 768px) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: none;
    opacity: 0.25;
    pointer-events: none;
    overflow: visible;
  }
`

const TopOverlayGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, #FBFFFF 3.59%, rgba(251, 255, 255, 0.00) 11.89%);
  z-index: 1;
  pointer-events: none;
`

const BottomOverlayGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(0deg, #FBFFFF 3.59%, rgba(251, 255, 255, 0.00) 11.89%);
  z-index: 1;
  pointer-events: none;
`

const UserBlocksContainer = styled.div`
  width: calc(100% - 20px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 20px;
`

const fadeInFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const UserBlock = styled.div`
  display: flex;
  align-items: center;
  width: 48%;
  padding: 5px;
  opacity: ${props => props.animate ? 0 : 1};
  ${props => props.animate && css`
    animation: ${fadeInFromTop} 0.5s ease-out forwards;
    animation-delay: ${props => props.index * 0.1}s;
  `}

  span.avatar-block {
    display: inline-block;
    min-width: 40px;
    height: 40px;
    margin-right: 10px;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 60px;
    padding-top: 40px;

    span.avatar-block {
      display: inline-block;
      min-width: 50px;
      height: 50px;
      margin-right: 10px;
      cursor: pointer;
    }
  }
`

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  filter: drop-shadow(0px 8px 12px rgba(167, 176, 192, 0.32));

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }
`

const SmallAvatar = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
  filter: drop-shadow(0px 8px 12px rgba(167, 176, 192, 0.32));
`

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 30px);
  max-width: calc(100% - 30px);

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
`

const UserName = styled.div`
  color: var(--Neutral-900);
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 18px;
    width: calc(100% - 50px);
  }
`

const UserActivity = styled.span`
  color: var(--Neutral-900);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
`

const GreenDot = styled.span`
  width: 8px;
  height: 8px;
  background-color: #4CAF50;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
`

const GreenBlockContainer = styled.div`
  max-width: 1260px;
  margin: 64px auto;
  margin-left: 20px;
  margin-right: 20px;
  width: calc(100% - 40px);
  background-color: var(--Brand-primary);
  background-image: url(${CDN_DOMAIN}/static/v2/green_block.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  aspect-ratio: 1350 / 470;
  padding: 30px;

  @media (max-width: 768px) {
    width: 100%;
    background-color: transparent;
    background-image: none;
    padding: 0 20px 20px;
    margin: 0 auto 32px;
    aspect-ratio: auto;
  }
`

const IconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`

const IconBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 22%;

  @media (max-width: 768px) {
    width: 100%;
    border-radius: 20px;
    border: 1px solid var(--Neutral-200, #ECF2F4);
    background: var(--color-light-100, #FFF);
    box-shadow: 0px 8px 12px 0px rgba(167, 176, 192, 0.32);
    padding: 20px;
    align-items: flex-start;
  }
`

const IconBackground = styled.div`
  width: 40px;
  height: 40px;
  padding: 6.232px 7.609px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--color-secondary);
  box-shadow: var(--shadow-300);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  img {
    content: url(${props => `${CDN_DOMAIN}/static/v2/${props.src}`});

    @media (max-width: 768px) {
      content: url(${props => `${CDN_DOMAIN}/static/v2/${props.src.replace('.svg', '-green.svg')}`});
    }
  }

  @media (max-width: 768px) {
    background: #ECFFFF;
  }
`

const StatsText = styled.p`
  color: var(--color-light-100);
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: center;
  margin-bottom: 20px;
  position: relative;

  @media (max-width: 768px) {
    color: var(--Neutral-900, #001723);
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin-bottom: 4px;
  }
`

const NameText = styled.p`
  color: var(--color-light-100);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media (max-width: 768px) {
    color: var(--Brand-500, var(--Bleu-300, #284F63));
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
  }
`

const ContentContainer = styled.div`
  max-width: 1260px;
  margin: 64px auto;
  display: flex;
  gap: 40px;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 768px) {
    margin: 32px auto;
    flex-direction: column;
    gap: 0;
    padding-left: 0px;
    padding-right: 0px;
  }
`

const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  height: 100vh;
  width: 340px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    display: none;
  }
`

const TableOfContents = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  transition: transform 0.3s ease;
`

const TOCItemContainer = styled.div`
  position: relative;
  padding-left: 16px;
  margin-bottom: 60px;

  &:last-child {
    margin-bottom: 0;
  }
`

const TOCItem = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const TOCIcon = styled.svg`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease;

  ${props => props.active && `
    transform: translateY(-50%) scale(1.4);
  `}
`

const TOCText = styled.span`
  margin-left: 34px;
  color: var(--Neutral-700, #485861);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  transition: color 0.3s ease;

  ${TOCItem}:hover & {
    color: var(--Brand-primary, #0CB2AD);
  }

  ${props => props.active && `
    color: var(--Neutral-900, #001723);
  `}
`

const TOCDivider = styled.div`
  position: absolute;
  left: 15.5px;
  top: calc(100% + 15px);
  width: 3px;
  height: 30px;
  background-color: var(--Brand-primary, #0CB2AD);
`

const ContentSection = styled.div`
  flex: 1;
  overflow-y: auto;

  @media (max-width: 768px) {
    position: relative;
    width: calc(100% - 40px);
    background: var(--Brand-primary, #0CB2AD);
    border-radius: 24px;
    margin-left: 20px;
    margin-right: 20px;
    overflow: hidden;
  }
`

const Section = styled.section`
  display: flex;
  min-height: 526px;
  padding: 10px 10px 10px 50px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  background: #CEF1F1;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    display: ${props => props.active ? 'flex' : 'none'};
    flex-direction: column;
    padding: 24px;
    background: transparent;
    border-radius: 0;
    margin-bottom: 0;
    min-height: auto;
  }
`

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50%;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`

const SectionNumber = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    color: var(--color-light-100);
    font-family: Unbounded;
    font-size: 48.557px;
    font-style: normal;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 8px;
  }
`

const SectionTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 20px;
  max-width: 321px;

  @media (max-width: 768px) {
    color: var(--color-light-100);
    font-size: 24.278px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    max-width: 100%;
    margin-top: 0;
    margin-bottom: 10px;
  }
`

const SectionDescription = styled.p`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
  max-width: 270px;

  @media (max-width: 768px) {
    color: #BEFCFA;
    font-size: 16.186px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    max-width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: ${props => props.expanded ? 'unset' : '2'};
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    max-height: ${props => props.expanded ? '1000px' : '60px'};
  }
`

const fadeInScale = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`

const ImageWrapper = styled.div`
  width: 374px;
  position: relative;
  margin-right: 40px;
  opacity: 0;
  ${props => props.isVisible && css`
    animation: ${fadeInScale} 0.3s ease-out forwards;
  `}

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    margin-top: 24px;
  }
`

const SectionImage = styled.div`
  display: flex;
  width: 374px;
  flex-direction: column;
  align-items: center;
  gap: 14.17px;
  border-radius: 16px;
  border: 10px solid var(--color-light-100, #FFF);
  background: #FAFBFD;
  position: relative;
  z-index: 2;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const TestimonialsContainer = styled.div`
  position: relative;
  max-width: 1260px;
  margin: 64px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    width: calc(100% - 40px);
    flex-direction: column;
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
  }
`

const TestimonialsText = styled.div`
  max-width: 555px;
  flex: 1;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 0px;
  }
`

const TestimonialsTitle = styled.h2`
  color: var(--Neutral-900, #001723);
  font-size: 46px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    color: var(--Main-color, #110D29);
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 0;
  }
`

const TestimonialsDescription = styled.p`
  color: var(--Neutral-700, #485861);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (max-width: 768px) {
    display: none;
  }
`

const TestimonialCarousel = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
`;

const TestimonialBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 550px;
  padding: 32px;
  border-radius: 24px;
  border: 1px solid var(--Neutral-200, #EFF0F6);
  background: var(--color-light-100, #FFF);
  box-shadow: var(--shadow-200, 0px 8px 12px 0px rgba(167, 176, 192, 0.32));
  gap: 24px;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    width: 100%;
    display: none;
    &:nth-child(${props => props.active + 1}) {
      margin-top: 24px;
      margin-bottom: 0px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      padding: 32px;
      border-radius: 24px;
      border: 1px solid var(--Neutral-200, #EFF0F6);
      background: var(--color-light-100, #FFF);
      box-shadow: var(--shadow-200, 0px 8px 12px 0px rgba(167, 176, 192, 0.32));
      animation: ${fadeIn} 0.5s ease-out forwards;
    }
    &:not(:nth-child(${props => props.active + 1})) {
      animation: ${fadeOut} 0.5s ease-out forwards;
    }
  }
`

const NavigationArrows = styled.div`
  display: none;
  width: 100%;

  @media (max-width: 768px) {
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: -20px;
  }
`

const ArrowButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const ArrowIcon = styled.img`
  width: 100px;
  height: 100px;
`

const AnimatedContent = styled.div`
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  opacity: ${props => props.isVisible ? 1 : 0};
  transform: ${props => props.isVisible ? 'scale(1)' : 'scale(0.95)'};
`;

const TestimonialText = styled.p`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 16px;
  margin-bottom: 16px;
`

const TestimonialTransitionParagraph = ({ text, maxLength = 150 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const shouldTruncate = text.length > maxLength;

  const truncatedText = shouldTruncate ? text.slice(0, maxLength) + '...' : text;

  return (
    <>
      <TestimonialText>
        "{isExpanded ? text : truncatedText}
        {shouldTruncate && (
          <>
            {" "}
            <InfoReadMoreLink onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? 'Lire moins' : 'Lire plus'}
            </InfoReadMoreLink>
          </>
        )}"
      </TestimonialText>
    </>
  );
};

const MemoizedTestimonials = memo(() => {
  const [currentTestimonial, setCurrentTestimonial] = useState(1);

  const testimonials = [
    {
      text: "J'utilise l'outil depuis quelques mois. Son principal avantage est qu'il permet de faciliter et de fluidifier les échanges avec les différents apporteurs d'affaires et augmente notre capacité à traiter les nombreuses opportunités d'implantations. De plus, la possibilité d'avoir pu saisir sur le site un cahier des charges précis en termes de secteur de recherche, de surface et de type d'emplacement, permet de recevoir des offres ciblées. Un seul mot : BRAVO pour le développement de cet outil !",
      name: "Frédéric MORAUT",
      role: "Responsable développement, KRYS GROUPE",
      avatar: "https://cdn.unemplacement.com/static/v2/krys-grey.png",
    },
    {
      text: "UnEmplacement.com un site que nous attendions tous, la recherche de locaux pour notre activité est déterminante, la fluidité et l’efficacité d’UnEmplacement.com révolutionne la manière d’acquérir des locaux. Nous l’utilisons depuis peu mais ressentons déjà son bénéfice dans notre développement.",
      name: "Alexis BOLLE",
      role: "Chargé de projets, ON AIR",
      avatar: "https://cdn.unemplacement.com/static/logos/on_air.png"
    },
    {
      text: "Nous avons le plaisir de travailler avec UnEmplacement depuis le début de son ouverture. La plateforme est très simple d'accès ce qui permet de répondre plus facilement à nos besoins en terme de locaux. De plus elle nous a permis d‘accroître notre réseau et surtout d'acquérir à notre enseigne une certaine notoriété. Merci à vous.",
      name: "Stéphane AGAC",
      role: "Fondateur, PIZZA TIME",
      avatar: "https://cdn.unemplacement.com/static/logos/pizza_time.png"
    }
  ];

  const handlePrevious = useCallback(() => {
    setTimeout(() => {
      setCurrentTestimonial((prev) => (prev > 1 ? prev - 1 : prev));
    }, 50);
  }, []);

  const handleNext = useCallback(() => {
    setTimeout(() => {
      setCurrentTestimonial((prev) => (prev < testimonials.length ? prev + 1 : prev));
    }, 50);
  }, [testimonials.length]);

  return (
    <TestimonialsContainer>
      <BackgroundBlurSecond />
      <TestimonialCarousel>
        <TestimonialsText>
          <TestimonialsTitle>Découvrez leurs témoignages</TestimonialsTitle>
          <TestimonialsDescription>
            Explorez les récits authentiques de sociétés qui ont trouvé leurs locaux idéaux grâce à notre plateforme. Découvrez comment notre outil a enrichi leur réseau, amplifié leur notoriété et simplifié leur recherche. Inspirez-vous de leur succès pour construire le vôtre.
          </TestimonialsDescription>
        </TestimonialsText>
        {testimonials.map((testimonial, index) => (
          <TestimonialBlock key={index} active={currentTestimonial}>
            <AnimatedContent isVisible={true}>
              <StarsContainer>
                <Stars>
                  {[...Array(5)].map((_, i) => (
                    <Star key={i}>★</Star>
                  ))}
                </Stars>
                {generateObfuscatedLink(
                  "/recommandations/enseignes",
                  "Voir tous les avis",
                  "text-neutral-600 hover:text-brand-primary font-medium text-sm font-montserrat leading-normal no-underline cursor-pointer transition duration-300 ease-in-out transform hover:scale-105",
                  {}
                )}
              </StarsContainer>
              <TestimonialTransitionParagraph text={testimonial.text} />
              <TestimonialIdentity>
                <IdentityAvatar src={testimonial.avatar} alt={testimonial.name} />
                <IdentityDetails>
                  <IdentityName>{testimonial.name}</IdentityName>
                  <IdentityRole>{testimonial.role}</IdentityRole>
                </IdentityDetails>
              </TestimonialIdentity>
            </AnimatedContent>
          </TestimonialBlock>
        ))}
      </TestimonialCarousel>
      <NavigationArrows>
        <ArrowButton onClick={handlePrevious} disabled={currentTestimonial === 1}>
          <ArrowIcon 
            src={`${CDN_DOMAIN}/static/v2/${currentTestimonial === 1 ? 'arrow-left-gray.svg' : 'arrow-left-gradient.svg'}`} 
            alt="Previous" 
          />
        </ArrowButton>
        <ArrowButton onClick={handleNext} disabled={currentTestimonial === testimonials.length}>
          <ArrowIcon 
            src={`${CDN_DOMAIN}/static/v2/${currentTestimonial === testimonials.length ? 'arrow-right-gray.svg' : 'arrow-right-gradient.svg'}`} 
            alt="Next" 
          />
        </ArrowButton>
      </NavigationArrows>
    </TestimonialsContainer>
  );
});

const StarsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Stars = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const Star = styled.span`
  color: var(--Brand-primary, #0CB2AD);
  font-size: 20px;
`

const TestimonialIdentity = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const IdentityAvatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`

const IdentityDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`

const IdentityName = styled.span`
  color: #000;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

const IdentityRole = styled.span`
  color: var(--Brand-primary, #0CB2AD);
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

const useTransactions = () => {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const transactionsData = await modelTransactionsFetch();

        setTransactions(transactionsData.transactions);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    fetchTransactions();
  }, []);

  return transactions;
};

const CardContainer = styled.div`
  max-width: 1260px;
  margin: 32px auto 64px;
  display: flex;
  justify-content: center;
  gap: 24px;
  flex-wrap: wrap;
`

const Card = styled.div`
  border-radius: 16px;
  background: var(--color-light-100, #FFF);
  box-shadow: 0px 12px 20px 0px rgba(167, 176, 192, 0.24);
  display: flex;
  width: 500px;
  height: 244px;
  padding: 16px;
  align-items: center;
  gap: 16px;

  @media (max-width: 768px) {
    margin-left: 10px;
    margin-right: 10px;
    width: 100%;
    height: auto;
    flex-direction: column-reverse;
  }
`

const CardContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const CardTitle = styled.h3`
  color: var(--Neutral-900);
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 16px;
  text-transform: none;
`

const CardTitleLink = styled.a`
  color: var(--Neutral-900);
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: var(--Brand-primary, #0CB2AD);
    text-decoration: none;
  }
`

const LinkList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const CardLink = styled.a`
  color: var(--Neutral-600);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`

const CardImage = styled(ImageComponent)`
  width: 203px;
  align-self: stretch;
  border-radius: 16px;
  object-fit: cover;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const cardData = [
  {
    title: "locaux commerciaux à louer",
    titleLink: "/local-commercial",
    links: [
      { text: "location local commercial paris", href: "/local-commercial/ile-de-france/paris-75000" },
      { text: "location local commercial bordeaux", href: "/local-commercial/gironde-33/bordeaux-33000" },
      { text: "location local commercial lyon", href: "/local-commercial/rhone-69/lyon-69000" },
      { text: "location local commercial marseille", href: "/local-commercial/bouches-du-rhone-13/marseille-13000" },
      { text: "location local commercial toulouse", href: "/local-commercial/haute-garonne-31/toulouse-31000" },
      { text: "location local commercial nice", href: "/local-commercial/alpes-maritimes-06/nice-06000" },
    ],
    image: `${CDN_DOMAIN}/static/v2/locaux-commerciaux-a-louer.png`
  },
  {
    title: "murs commerciaux à vendre",
    titleLink: "/local-commercial-vente",
    links: [
      { text: "vente local commercial paris", href: "/local-commercial-vente/ile-de-france/paris-75000" },
      { text: "vente local commercial bordeaux", href: "/local-commercial-vente/gironde-33/bordeaux-33000" },
      { text: "vente local commercial lyon", href: "/local-commercial-vente/rhone-69/lyon-69000" },
      { text: "vente local commercial marseille", href: "/local-commercial-vente/bouches-du-rhone-13/marseille-13000" },
      { text: "vente local commercial toulouse", href: "/local-commercial-vente/haute-garonne-31/toulouse-31000" },
      { text: "vente local commercial nice", href: "/local-commercial-vente/alpes-maritimes-06/nice-06000" },
    ],
    image: `${CDN_DOMAIN}/static/v2/murs-commerciaux-a-vendre.png`
  },
  {
    title: "entrepôts à louer",
    titleLink: "/entrepots",
    links: [
      { text: "location entrepôt caen", href: "/entrepots/calvados-14/caen-14000" },
      { text: "location entrepôt alpes maritimes", href: "/entrepots/provence-alpes-cote-d-azur/alpes-maritimes-06" },
      { text: "location entrepôt annecy", href: "/entrepots/haute-savoie-74/annecy-74000" },
      { text: "location entrepôt la rochelle", href: "/entrepots/charente-maritime-17/la-rochelle-17000" },
      { text: "location entrepôt bourges", href: "/entrepots/cher-18/bourges-18000" },
      { text: "location entrepôt tours", href: "/entrepots/indre-et-loire-37/tours-37000" },
    ],
    image: `${CDN_DOMAIN}/static/v2/entrepots-a-louer.png`
  },
  {
    title: "entrepôts à vendre",
    titleLink: "/entrepots-vente",
    links: [
      { text: "vente entrepôt alpes maritimes", href: "/entrepots-vente/provence-alpes-cote-d-azur/alpes-maritimes-06" },
      { text: "vente entrepôt bayonne", href: "/entrepots-vente/pyrenees-atlantiques-64/bayonne-64100" },
      { text: "vente entrepôt lorient", href: "/entrepots-vente/morbihan-56/lorient-56100" },
      { text: "vente entrepôt montpellier", href: "/entrepots-vente/herault-34/montpellier-34000" },
      { text: "vente entrepôt reims", href: "/entrepots-vente/marne-51/reims-51100" },
      { text: "vente entrepôt nîmes", href: "/entrepots-vente/gard-30/nimes-30000" },
    ],
    image: `${CDN_DOMAIN}/static/v2/entrepots-a-vendre.png`
  },
  {
    title: "bureaux à louer",
    titleLink: "/bureaux",
    links: [
      { text: "location bureaux la rochelle", href: "/bureaux/charente-maritime-17/la-rochelle-17000" },
      { text: "location bureaux dijon", href: "/bureaux/cote-d-or-21/dijon-21000" },
      { text: "location bureaux aix en provence", href: "/bureaux/bouches-du-rhone-13/aix-en-provence-13080" },
      { text: "location bureaux metz", href: "/bureaux/moselle-57/metz-57000" },
      { text: "location bureaux rennes", href: "/bureaux/ille-et-vilaine-35/rennes-35000" },
      { text: "location bureaux le havre", href: "/bureaux/seine-maritime-76/le-havre-76600" },
    ],
    image: `${CDN_DOMAIN}/static/v2/bureaux-a-louer.png`
  },
  {
    title: "bureaux à vendre",
    titleLink: "/bureaux-vente",
    links: [
      { text: "vente bureaux dijon", href: "/bureaux-vente/cote-d-or-21/dijon-21000" },
      { text: "vente bureaux le havre", href: "/bureaux-vente/seine-maritime-76/le-havre-76600" },
      { text: "vente bureaux paris 10", href: "/bureaux-vente/ile-de-france/paris-10eme-arrondissement-75010" },
      { text: "vente bureaux nîmes", href: "/bureaux-vente/gard-30/nimes-30000" },
      { text: "vente bureaux grenoble", href: "/bureaux-vente/isere-38/grenoble-38000" },
      { text: "vente bureaux annecy", href: "/bureaux-vente/haute-savoie-74/annecy-74000" },
    ],
    image: `${CDN_DOMAIN}/static/v2/bureaux-a-vendre.png`
  },
  {
    title: "fonds de commerce à vendre",
    titleLink: "/fonds-commerce",
    links: [
      { text: "fonds de commerce paris", href: "/fonds-commerce/ile-de-france/paris-10eme-arrondissement-75010" },
      { text: "fonds de commerce strasbourg", href: "/fonds-commerce/bas-rhin-67/strasbourg-67000" },
      { text: "fonds de commerce nice", href: "/fonds-commerce/alpes-maritimes-06/nice-06000" },
      { text: "fonds de commerce toulouse", href: "/fonds-commerce/haute-garonne-31/toulouse-31000" },
      { text: "fonds de commerce montpellier", href: "/fonds-commerce/herault-34/montpellier-34000" },
      { text: "fonds de commerce bordeaux", href: "/fonds-commerce/gironde-33/bordeaux-33000" },
    ],
    image: `${CDN_DOMAIN}/static/v2/fonds-de-commerce-a-vendre.png`
  },
  {
    title: "terrains à vendre",
    titleLink: "/terrain",
    links: [
      { text: "terrain haute savoie", href: "/terrain/auvergne-rhone-alpes/haute-savoie-74" },
      { text: "terrain haut rhin", href: "/terrain/grand-est/haut-rhin-68" },
      { text: "terrain bas rhin", href: "/terrain/grand-est/bas-rhin-67" },
      { text: "terrain var", href: "/terrain/provence-alpes-cote-d-azur/var-83" },
      { text: "terrain savoie", href: "/terrain/auvergne-rhone-alpes/savoie-73" },
      { text: "terrain bouches du rhone", href: "/terrain/provence-alpes-cote-d-azur/bouches-du-rhone-13" },
    ],
    image: `${CDN_DOMAIN}/static/v2/terrains-a-vendre.png`
  }
]

const EstimationContainer = styled.div`
  max-width: 1260px;
  margin: 64px auto 0;
  margin-top: 100px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    margin: 0;
  }
`

const EstimationTitle = styled.p`
  color: var(--Neutral-500);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    font-size: 16px;
    font-weight: 600;
    text-transform: none;
    margin-bottom: 5px;
  }
`

const EstimationDescription = styled.h2`
  color: var(--Neutral-Black);
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  max-width: 625px;

  span {
    color: var(--Brand-primary);
  }

  @media (max-width: 768px) {
    color: var(--Main-color);
    font-size: 24px;
    text-transform: uppercase;
    width: 300px;
  }
`

const PropertyTypeCards = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Check if window is defined (client-side)
    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth <= 768);
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  const getMobileImageSrc = (src) => {
    if (isMobile) {
      const parts = src.split('.');
      const extension = parts.pop();
      return `${parts.join('.')}-mobile.${extension}`;
    }
    return src;
  };

  return (
    <>
      <EstimationContainer>
        <EstimationTitle>Besoin d'une estimation ?</EstimationTitle>
        <EstimationDescription>
          Découvrez les loyers et les <span>prix les plus recherchés</span>
        </EstimationDescription>
      </EstimationContainer>
      <CardContainer>
        {cardData.map((card, index) => (
          <Card key={index}>
            <CardContent>
              <CardTitle>
                Pour des&nbsp;
                <CardTitleLink href={card.titleLink}>
                  {card.title}
                </CardTitleLink>
              </CardTitle>
              <LinkList>
                {card.links.map((link, linkIndex) => (
                  <CardLink key={linkIndex} href={link.href}>{link.text}</CardLink>
                ))}
              </LinkList>
            </CardContent>
            <CardImage 
              src={getMobileImageSrc(card.image)} 
              alt={card.title} 
            />
          </Card>
        ))}
      </CardContainer>
    </>
  );
};

const ButtonWrapper = styled.div`
  width: 85px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  background: none;
  padding: 5px 2.5px 0;
  border-radius: 5px;
  margin: 2px;
  margin-top: 20px;
  transition: transform 0.5s ease-in-out;
  transform: ${props => props.isHovered || props.isActive ? 'scale(1.05)' : 'scale(1)'};

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 2px;
  }
`

const IconWrapper = styled.div`
  box-shadow: ${props => props.isHovered || props.isActive ? 'var(--shadow-200)' : 'none'};
  background: ${props => props.isHovered || props.isActive ? 'var(--Brand-linear)' : 'none'};
  border: ${props => props.isHovered || props.isActive ? 'none' : '1px dashed var(--Neutral-600)'};
  border-radius: 10px;
  display: inline-block;
  margin: auto;
  width: 55px;
  height: 55px;
  transition: background 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
`

const IconInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Label = styled.div`
  display: flex;
  justify-content: center;
  font-size: 10px;
  color: ${props => props.isHovered || props.isActive ? 'var(--color-old-uec-green)' : 'var(--Neutral-600)'};
  margin: 10px 0;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  align-items: center;
  height: 20px;
  transition: color 0.5s ease-in-out;
  transform: ${props => props.isHovered || props.isActive ? 'scale(1.05)' : 'scale(1)'};

  @media (max-width: 768px) {
    margin-top: 15px;
    font-size: 14px;
  }
`

const TypeButton = memo(({ type, selectedType, setSelectedType, setHoveredType }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleTypeHover = useCallback((isHovered) => {
    setIsHovered(isHovered);
    setHoveredType(isHovered ? type.prospection_type : null);
  }, [setHoveredType, type.prospection_type]);

  const handleTypeSelect = useCallback(() => {
    if (type.prospection_type === selectedType) {
      return setSelectedType(null);
    }
    setSelectedType(type.prospection_type);
  }, [setSelectedType, type.prospection_type, selectedType]);

  const isActive = selectedType === type.prospection_type;

  const iconSize = 55;

  return (
    <ButtonWrapper
      isHovered={isHovered}
      isActive={isActive}
      onMouseEnter={() => handleTypeHover(true)}
      onMouseLeave={() => handleTypeHover(false)}
      onClick={handleTypeSelect}
    >
      <IconWrapper isActive={isActive} isHovered={isHovered}>
        <IconInner>
          {type.icon(
            iconSize,
            iconSize,
            2,
            isHovered || isActive ? 'var(--color-light-100)' : 'var(--Neutral-600)'
          )}
        </IconInner>
      </IconWrapper>
      <Label isActive={isActive} isHovered={isHovered}>
        {type.label}
      </Label>
    </ButtonWrapper>
  );
})

const TypeButtonsContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
`

const TypeButtonWrapper = styled.div`
  @media (max-width: 768px) {
    width: 100%;

    &:last-child {
      grid-column: span 3; /* L'élément occupe toutes les colonnes */
      justify-self: center; /* Centre l'élément sur la ligne */
    }
  }
`

const MobileHeading = styled.h1`
  @media (max-width: 768px) {
    color: var(--Neutral-900);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    width: 300px;
  }
`

const StyledParagraph = styled.p`
  @media (max-width: 768px) {
    padding: 20px 20px 0 0px;
  }
`

const MemoizedLeftContainer = memo(({ selectedType, setSelectedType, setHoveredType }) => {
  return (
    <LeftContainer>
      <MobileHeading>
        Accédez aux meilleurs emplacements
      </MobileHeading>
      <TextWrapper>
        <p className="classic-text max-w-[500px]">
          Communiquez votre recherche à 800+ agences et foncières spécialisées en immobilier d'entreprise. <b>Recevez des dossiers off-market</b> adaptés à vos critères.
        </p>
      </TextWrapper>
      <ShadowContainer className="shadow-200">
        <StyledParagraph className="classic-text !font-semibold">Que recherchez-vous ?</StyledParagraph>
        <TypeButtonsContainer>
          {types
            .filter(t => !t.hidden)
            .map(type => (
              <TypeButtonWrapper key={type.prospection_type}>
                <TypeButton
                  key={type.prospection_type}
                  type={type}
                  selectedType={selectedType}
                  setSelectedType={setSelectedType}
                  setHoveredType={setHoveredType}
                />
              </TypeButtonWrapper>
            ))}
        </TypeButtonsContainer>
      </ShadowContainer>
      <ButtonContainer>
        <Button
          onClick={() => {
            if (!selectedType) {
              return
            }

            const redirectTypes = {
              1: 'onboarding-local-commercial',
              3: 'onboarding-pop-up-store',
              4: 'onboarding-terrain',
              5: 'onboarding-bureaux',
              7: 'onboarding-entrepots',
              8: 'onboarding-investissement',
              9: 'onboarding-fonds-de-commerce'
            }

            window.location = `/${redirectTypes[selectedType]}`
          }}
          className="primary-button"
        >
          Lancer ma recherche
          <ArrowForwardIcon />
        </Button>
        <FreeServiceArrow className="flex flex-row md:flex-col items-center gap-2">
          <img src={`${CDN_DOMAIN}/static/v2/free-service-arrow.svg`} alt="Un service 100% gratuit" />
          <span>Un service <b>100% gratuit</b>,<br />sans commission</span>
        </FreeServiceArrow>
      </ButtonContainer>
    </LeftContainer>
  )
});

const TransactionCard = styled.div`
  flex: 0 0 auto;
  width: 363px;
  height: 391px;
  margin-right: 20px;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  box-shadow: var(--shadow-300);
  margin-bottom: 30px;
  background: white;
  display: flex;
  flex-direction: column;
  cursor: ${props => props.isSwiping ? 'grabbing' : 'pointer'};
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: 768px) {
    width: 100%;

    &:hover {
      transform: none;
    }
  }
`

const TransactionImageBlock = styled.div`
  position: relative;
  width: 100%;
  height: 240px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px 10px 0 0;
  background-color: #e6f2f2;
`

const TransactionTypeBadge = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 16px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.80);
  backdrop-filter: blur(2px);
  font-size: 16px;
  font-weight: 500;
  color: var(--Neutral-900);
`

const TransactionContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 16px;
`

const TransactionDetail = styled.div`
  display: flex;
  align-items: center;
  margin: 6px;
  line-height: 1.2;
`

const TransactionsContainer = styled.div`
  width: calc(100% - 40px);
  max-width: 1260px;
  margin: 64px auto;
  margin-left: 20px;
  margin-right: 20px;

  @media (max-width: 768px) {
    margin: 46px auto;
    margin-bottom: 0;
    margin-left: 0px;
    margin-right: 0px;
  }
`

const TransactionsTitle = styled.h2`
  color: var(--Neutral-900, #001723);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    color: var(--Main-color, #110D29);
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }
`

const TransactionsDescription = styled.p`
  width: 528px;
  color: var(--Neutral-700, #485861);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    display: none;
  }
`

const ScrollContainer = styled.div`
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
`

const TransactionsScroll = styled.div`
  display: flex;
  overflow-x: auto;
  cursor: grab;
  padding: 0 40px;
  margin: 0 -40px;
  padding-top: 5px;

  &:active {
    cursor: grabbing;
  }

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;

  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
    padding-top: 5px;
  }
`

const FadeEdge = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 40px;
  pointer-events: none;
  z-index: 1;
`

const LeftFade = styled(FadeEdge)`
  left: 0;
  background: linear-gradient(to right, white 5%, rgba(255, 255, 255, 0));

  @media (max-width: 768px) {
    background: linear-gradient(to right, white 1%, rgba(255, 255, 255, 0) 90%);
  }
`

const RightFade = styled(FadeEdge)`
  right: 0;
  background: linear-gradient(to left, white 5%, rgba(255, 255, 255, 0));

  @media (max-width: 768px) {
    background: linear-gradient(to left, white 1%, rgba(255, 255, 255, 0) 90%);
  }
`

const MobileArrowContainer = styled.div`
  display: none;

  @media (max-width: 768px) {
    position: relative;
    top: -20px;
    display: flex;
    justify-content: flex-end;
  }
`;

const MemoizedTransactionCard = memo(({ transaction, index, config }) => {
  const data = transaction.data.data;
  const isLease = data['informations-principales/type-de-transaction/radio'].indexOf('location') !== -1;
  const agencyData = transaction.agency;
  const cityLabel = `${(_.isArray(data['location/address/address'].nc) ? _.last(data['location/address/address'].nc) : data['location/address/address'].nc).replace('e Arrondissement', '').replace('er Arrondissement', '')}`.trim();
  const firstname = data['informations-principales/intervenant-locataire/firstname'] || data['informations-principales/intervenant-vente/firstname'];
  const lastname = data['informations-principales/intervenant-locataire/lastname'] || data['informations-principales/intervenant-vente/lastname'];
  
  const generateTransactionURL = useCallback((transaction) => {
    let url = '/transactions/';
    const cityLabel = `${(_.isArray(transaction.data.data['location/address/address'].nc) ? _.last(transaction.data.data['location/address/address'].nc) : transaction.data.data['location/address/address'].nc).replace('e Arrondissement', '').replace('er Arrondissement', '')}`.trim();
    url += `${getId(cityLabel)}`;
    url += `-${transaction.data.data['informations-principales/type-de-transaction/radio']}`;
    url += `-${transaction.data.data['informations-principales/type-de-bien/radio']}`;
    url += `/${transaction.data.reference_id}`;
    return url;
  }, []);

  return (
    <TransactionCard
      onClick={(e) => {
        window.location.href = generateTransactionURL(transaction);
      }}
    >
      <TransactionImageBlock 
        style={{ 
          backgroundImage: `url(${data['visuals/photos/upload'][0].url})` 
        }} 
      >
        <TransactionTypeBadge>
          {(() => {
            switch (transaction.data.type) {
              case 'local-commercial':
                return 'Local commercial';
              case 'droit-au-bail':
                return 'Fonds de commerce';
              case 'fonds-commerce':
                return 'Fonds de commerce';
              case 'terrain':
                return 'Terrain';
              case 'bureau':
                return 'Bureaux';
              case 'entrepot':
                return 'Entrepôt / Local d‘activité';
              case 'immeuble':
                return 'Immeuble';
              default:
                return '';
            }
          })()}
        </TransactionTypeBadge>
      </TransactionImageBlock>
      <TransactionContent>
        <TransactionDetail>
          <div className="w-[45px] h-[30px] flex items-center justify-center">
            <PinIcon color="url(#gradient2)" width={20} height={20} />
          </div>
          <span className="w-[calc(100%-45px)]">
            <div className="overflow-hidden whitespace-nowrap text-ellipsis">
              {data['location/address/address'].selected_housenumber ? data['location/address/address'].selected_housenumber.number : ''}{data['location/address/address'].selected_housenumber && data['location/address/address'].selected_housenumber.abreviation ? data['location/address/address'].selected_housenumber.abreviation : ''} {data['location/address/address'].nv}
            </div>
            {cityLabel}
          </span>
        </TransactionDetail>
        <TransactionDetail>
          <div className="w-[45px] h-[30px] flex items-center justify-center">
            <HandshakeIcon color="url(#gradient2)" width={25} height={25} />
          </div>
          <span className="w-[calc(100%-45px)]">
            <ResponsiveTextTruncate
              line={2}
              element="div"
              truncateText="…"
              text={`${isLease ? 'Loué' : 'Vendu'} en ${moment(transaction.data.transaction_date).format('MMM YYYY')} ${transaction.data.author_active ? 'par' : ''} ${transaction.data.author_active ? `${firstname} ${lastname}` : ''}`.trim()}
            />
          </span>
        </TransactionDetail>
        <TransactionDetail>
          <a
            href={agencyData.profilePublished ? `https://${config.websiteDomain}/${agencyData.propertyCompany ? 'fonciere' : 'agence-immobiliere'}/${getId(agencyData.name)}-${agencyData.url_id}` : 'javascript:void(0)'}
          >
            <SmallAvatar
              src={agencyData.avatarURL}
              title={agencyData.name}
            />
          </a>
          <span className="w-[calc(100%-45px)]">
            <a
              href={agencyData.profilePublished ? `https://${config.websiteDomain}/${agencyData.propertyCompany ? 'fonciere' : 'agence-immobiliere'}/${getId(agencyData.name)}-${agencyData.url_id}` : 'javascript:void(0)'}
            >
              <ResponsiveTextTruncate
                line={2}
                element="div"
                truncateText="…"
                text={agencyData.name}
              />
            </a>
          </span>
        </TransactionDetail>
      </TransactionContent>
    </TransactionCard>
  );
});

const TransactionsMemo = memo(({ config, transactions }) => {
  const scrollRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isMobile || isHovering) return;

    const scrollElement = scrollRef.current;
    if (!scrollElement) return;

    const totalWidth = scrollElement.scrollWidth;
    const viewportWidth = scrollElement.offsetWidth;

    const scroll = () => {
      setScrollPosition((prevPosition) => {
        const newPosition = prevPosition + 1;
        if (newPosition >= totalWidth / 2) {
          return 0;
        }
        return newPosition;
      });
    };

    const intervalId = setInterval(scroll, 50);

    return () => clearInterval(intervalId);
  }, [transactions, isMobile, isHovering]);

  useEffect(() => {
    if (!isMobile && scrollRef.current) {
      scrollRef.current.scrollLeft = scrollPosition;
    }
  }, [scrollPosition, isMobile]);

  const handlePrevious = useCallback(() => {
    if (!isMobile) return;
    setCurrentIndex((prev) => (prev > 0 ? prev - 1 : prev));
  }, [isMobile]);

  const handleNext = useCallback(() => {
    if (!isMobile) return;
    setCurrentIndex((prev) => (prev < transactions.length - 1 ? prev + 1 : prev));
  }, [isMobile, transactions.length]);

  useEffect(() => {
    if (isMobile && scrollRef.current) {
      scrollRef.current.scrollTo({
        left: currentIndex * (scrollRef.current.offsetWidth + 20),
        behavior: 'smooth'
      });
    }
  }, [currentIndex, isMobile]);

  return (
    <TransactionsContainer>
      <TransactionsTitle>Les transactions à la une</TransactionsTitle>
      <TransactionsDescription>
        Découvrez les derniers deals réalisés par les professionnels membres de notre communauté
      </TransactionsDescription>
      <ScrollContainer>
        <LeftFade />
        <RightFade />
        <TransactionsScroll 
          ref={scrollRef}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          {[...transactions, ...transactions].map((transaction, index) => (
            <MemoizedTransactionCard
              key={`${transaction.id}-${index}`}
              transaction={transaction}
              index={index}
              config={config}
            />
          ))}
        </TransactionsScroll>
      </ScrollContainer>
      {isMobile && (
        <MobileArrowContainer>
          <ArrowButton onClick={handlePrevious} disabled={currentIndex === 0}>
            <ArrowIcon 
              src={`${CDN_DOMAIN}/static/v2/${currentIndex === 0 ? 'arrow-left-gray.svg' : 'arrow-left-gradient.svg'}`} 
              alt="Previous" 
            />
          </ArrowButton>
          <ArrowButton onClick={handleNext} disabled={currentIndex === transactions.length - 1}>
            <ArrowIcon 
              src={`${CDN_DOMAIN}/static/v2/${currentIndex === transactions.length - 1 ? 'arrow-right-gray.svg' : 'arrow-right-gradient.svg'}`} 
              alt="Next" 
            />
          </ArrowButton>
        </MobileArrowContainer>
      )}
    </TransactionsContainer>
  );
});

const UrgentSearchMemo = memo(() => {
  const [isVisible, setIsVisible] = useState(false);
  const urgentSearchRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (urgentSearchRef.current) {
      observer.observe(urgentSearchRef.current);
    }

    return () => {
      if (urgentSearchRef.current) {
        observer.unobserve(urgentSearchRef.current);
      }
    };
  }, []);

  return (
    <UrgentSearchContainer ref={urgentSearchRef} id="urgentSearch">
      <UrgentSearchText>
        <UrgentSearchTitle>Votre recherche est <span>urgente ?</span></UrgentSearchTitle>
        <UrgentSearchDescription>
          Accédez aux coordonnées directes de 2000+ professionnels (brokers, foncières...) par zone de travail. <strong>Fonctionnalité réservée aux membres</strong> ayant envoyé leur recherche.
        </UrgentSearchDescription>
        <TransactionButton 
          className="max-w-[250px] !mt-8"
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          Lancer ma recherche
        </TransactionButton>
      </UrgentSearchText>
      <UrgentSearchImageWrapper isVisible={isVisible}>
        <ImageBackground />
        <UrgentSearchImage>
          <ImageComponent
            src={`${CDN_DOMAIN}/static/v2/recherche_urgente.png`}
            alt="Recherche urgente"
          />
        </UrgentSearchImage>
      </UrgentSearchImageWrapper>
    </UrgentSearchContainer>
  );
});

const UrgentSearchContainer = styled.div`
  max-width: 1260px;
  margin: 0px auto;
  padding: 64px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 32px auto;
    margin-top: 0px;
    margin-bottom: 48px;
    padding: 0 20px;
  }
`;

const UrgentSearchText = styled.div`
  position: relative;
  max-width: 498px;
  flex: 1;

  @media (max-width: 768px) {
    margin-bottom: 24px;
    max-width: 100%;
  }
`;

const UrgentSearchTitle = styled.h2`
  color: var(--Neutral-900, #001723);
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-bottom: 20px;

  span {
    color: var(--Brand-primary, #0CB2AD);
  }

  @media (max-width: 768px) {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

const UrgentSearchDescription = styled.p`
  color: var(--Neutral-900, #001723);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  strong {
    font-weight: 600;
  }
`;

const UrgentSearchImageWrapper = styled.div`
  position: relative;
  width: 474px;
  margin-right: 20px;
  opacity: ${props => props.isVisible ? 1 : 0};
  transition: opacity 0.3s ease-out;

  @media (max-width: 768px) {
    width: calc(100% - 20px);
    order: 1;
    margin-bottom: 24px;
  }
`;

const ImageBackground = styled.div`
  position: absolute;
  top: 15px;
  left: 20px;
  bottom: -15px;
  right: -20px;
  border-radius: 16px;
  background: linear-gradient(140deg, #22DAD1 -1.62%, #3AE7B1 99.8%);
  z-index: 1;
`

const SectionImageBackground = styled(ImageBackground)`
  @media (max-width: 768px) {
    display: none;
  }
`

const UrgentSearchImage = styled(SectionImage)`
  width: 474px;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const MemoizedImageGrid = memo(({ images }) => (
  <ImageGrid>
    <div className="grid-container">
      {images.map((image, index) => (
        <a href={`${CDN_DOMAIN}/static/testimonials/${image.doc}`} target="_blank">
          <ImageBlock key={index}>
            <BlockImage
              src={`${CDN_DOMAIN}/static/v2/${image.src}`} 
              alt={image.alt}
              scale={image.scale}
            />
          </ImageBlock>
        </a>
      ))}
    </div>
  </ImageGrid>
))

const MemoizedIconsContainer = memo(({ icons }) => (
  <IconsContainer>
    {icons.map((icon) => (
      <IconBox key={icon.name}>
        <IconBackground src={icon.src}>
          <img alt={icon.name} width="40" height="40" />
        </IconBackground>
        <StatsText>
          {icon.stats}
          <span
            style={{ width: icon.width }}
            className="absolute bottom-[-3.5px] left-0 h-[1.5px] bg-white hidden md:block"
          />
        </StatsText>
        <NameText>{icon.name}</NameText>
      </IconBox>
    ))}
  </IconsContainer>
))

const MemoizedEventsContainer = memo(() => {
  return (
    <EventsContainer>
      <EventsSVGBackground>
        <EventsTextContainer>
          <EventsTitle>Accédez à des événements privés</EventsTitle>
          <EventsDescription>
            Rencontrez les décideurs clés du marché de l'immobilier d'entreprise
          </EventsDescription>
          <EventsButton onClick={() => window.location.href = 'https://blog.unemplacement.com/petits-dejeuners/'}>
            <div className="flex items-center justify-center">
              En savoir plus
              <ArrowForwardIcon className="ml-2" />
            </div>
          </EventsButton>
        </EventsTextContainer>
      </EventsSVGBackground>
    </EventsContainer>
  );
});

const EventsContainer = styled.div`
  max-width: 1260px;
  width: calc(100% - 40px);
  margin: 64px auto;
  background-image: url(${CDN_DOMAIN}/static/v2/evenements.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
  height: 593px;
  position: relative;
  overflow: hidden;
  margin-left: 20px;
  margin-right: 20px;

  @media only screen and (min-resolution: 1dppx) {
    background-image: image-set(url(${CDN_DOMAIN}/static/v2/evenements.png) 1x, url(${CDN_DOMAIN}/static/v2/evenements@2x.png) 2x);
  }

  @media only screen and (min-resolution: 2dppx) {
    background-image: image-set(url(${CDN_DOMAIN}/static/v2/evenements@2x.png) 2x, url(${CDN_DOMAIN}/static/v2/evenements@3x.png) 3x);
  }

  @media (max-width: 768px) {
    width: 100%;
    background-image: url(${CDN_DOMAIN}/static/v2/evenements_mobile.png);
    border-radius: 0;
    aspect-ratio: 360 / 372;
    height: auto;
    background-size: contain;
    margin: 0px auto;
    margin-left: 0px;
    margin-right: 0px;

    @media only screen and (min-resolution: 1dppx) {
      background-image: image-set(url(${CDN_DOMAIN}/static/v2/evenements_mobile.png) 1x, url(${CDN_DOMAIN}/static/v2/evenements_mobile@2x.png) 2x);
    }

    @media only screen and (min-resolution: 2dppx) {
      background-image: image-set(url(${CDN_DOMAIN}/static/v2/evenements_mobile@2x.png) 2x, url(${CDN_DOMAIN}/static/v2/evenements_mobile@3x.png) 3x);
    }
  }
`

const EventsSVGBackground = styled.div`
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  width: 637px;
  height: 593px;
  background-image: url(${CDN_DOMAIN}/static/v2/event_section_container.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    background-image: none;
    background: transparent;
    width: 100%;
    aspect-ratio: 360 / 372;
    height: auto;
    padding: 20px;
    transform: none;
    top: 0;
    left: 0;
  }
`

const EventsTextContainer = styled.div`
  margin-left: 200px;
  margin-right: 50px;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-right: 0;
  }
`

const EventsTitle = styled.h2`
  color: var(--color-light-100, #FFF);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 16px;
`

const EventsDescription = styled.p`
  max-width: 80%;
  color: var(--color-light-100, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 18px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`

const EventsButton = styled(TransactionButton)`
  background-color: var(--color-light-100);
  color: var(--Brand-primary);
  max-width: 200px;

  &:hover {
    background: var(--color-light-200);
  }
`

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-container': {
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        margin: 0,
      },
    },
    '& .MuiDialog-paper': {
      borderRadius: '24px',
      background: 'var(--color-light-100, #FFF)',
      padding: '0 32px 32px 32px',
      [theme.breakpoints.down('sm')]: {
        padding: '0 16px 16px 16px',
        margin: 0,
        width: '100%',
        maxWidth: '100%'
      },
    },
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    color: '#000',
    fontSize: '30px',
    fontStyle: 'normal',
    fontWeight: 800,
    lineHeight: 'normal',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  closeButton: {
    color: '#000',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  dialogContent: {
    padding: 0,
  },
  image: {
    width: '100%',
    height: 'auto',
  },
}));

const VideoWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; // Ratio 16:9
  width: 100%;
  border-radius: 24px;
  overflow: hidden;
`;

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;

  .react-player__controls {
    display: none !important;
  }
`;

const MemoizedVideoDialog = memo(({ openDialog, handleCloseDialog, classes }) => {
  const playerRef = useRef(null);
  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      maxWidth="md"
      fullWidth
      className={classes.dialog}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <span className={classes.title}>Le reportage Capital <img src={`${CDN_DOMAIN}/static/v2/m6_color.svg`} className="ml-[10px] h-[20px] md:h-[30px]" alt="M6 Capital" /></span>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseDialog}>
          <DesktopOnly>
            Fermer la vidéo
          </DesktopOnly>
          <MobileOnly>
            <CloseIcon />
          </MobileOnly>
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <VideoWrapper>
          <StyledReactPlayer
            ref={playerRef}
            light={`${CDN_DOMAIN}/static/videos/capital_m6.png`}
            url={`${CDN_DOMAIN}/static/videos/capital_m6.mp4`}
            width="100%"
            height="100%"
            controls={false}
            playing={true}
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload nofullscreen',
                },
              },
            }}
            playIcon={<PlayIcon />}
          />
        </VideoWrapper>
      </DialogContent>
    </Dialog>
  );
});

const DesktopVideoDialog = memo(() => {
  const [openDialog, setOpenDialog] = useState(false);
  const classes = useStyles();

  const handleOpenDialog = useCallback(() => setOpenDialog(true), []);
  const handleCloseDialog = useCallback(() => setOpenDialog(false), []);

  return (
    <>
      <CapitalButton onClick={handleOpenDialog}>
        Voir le reportage Capital
        <ButtonLogo src={`${CDN_DOMAIN}/static/v2/m6.png`} alt="M6 Capital" />
      </CapitalButton>
      <MemoizedVideoDialog 
        openDialog={openDialog} 
        handleCloseDialog={handleCloseDialog} 
        classes={classes}
      />
    </>
  );
});

const MobileVideoDialog = memo(() => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <>
      <CapitalButton onClick={handleOpen}>
        Voir le reportage Capital
        <ButtonLogo src={`${CDN_DOMAIN}/static/v2/m6.png`} alt="M6 Capital" />
      </CapitalButton>
      <MemoizedVideoDialog 
        openDialog={open} 
        handleCloseDialog={handleClose} 
        classes={classes}
      />
    </>
  );
});

const OnlineUsersComponent = memo(({ onlineUsers, animate }) => {
  return (
    <OnlineUsersContainer>
      <TopOverlayGradient />
      <BottomOverlayGradient />
      <UserBlocksContainer>
        {onlineUsers.map((user, index) => (
          <UserBlock key={`${user.id || index}`} animate={animate} index={index}>
            {user.agency_url ?
              generateObfuscatedLink(
                user.agency_url,
                <Avatar src={user.avatar} alt={user.name} />,
                'avatar-block',
                {}
              ) : <Avatar src={user.avatar} alt={user.name} className="mr-[10px]" />
            }
            <UserInfo>
              <UserName>
                {user.agency_url ?
                  generateObfuscatedLink(
                    user.agency_url,
                    user.name,
                    '',
                    {
                      cursor: 'pointer'
                    }
                  ) : user.name
                }
              </UserName>
              <UserActivity>
                <GreenDot />
                {calculateTimeSince(user.created_at_ts)}
              </UserActivity>
            </UserInfo>
          </UserBlock>
        ))}
      </UserBlocksContainer>
    </OnlineUsersContainer>
  );
});

const CommunityTitle = styled.div`
  max-width: 344px;
  color: var(--Neutral-500);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  @media (max-width: 768px) {
    color: var(--Neutral-500);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: none;
    position: relative;
    z-index: 2;
  }
`

const TitleBlockSecond = styled.div`
  max-width: 544px;
  position: relative;
  z-index: 2;

  @media (max-width: 768px) {
    width: 100%;
  }

  h2 {
    color: var(--Neutral-900);
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;

    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
`

const CommunityContainerMemo = memo(({ onlineUsers, animate }) => {
  return (
    <CommunityWrapper>
      <OnlineUsersComponent 
        onlineUsers={onlineUsers} 
        animate={animate}
      />
      <CommunityContainer>
        <TitleBlockSecond>
          <CommunityTitle>
            Une communauté réactive
          </CommunityTitle>
          <h2 className="text-[40px] font-extrabold mt-[10px] mb-[10px]">OBTENEZ <HighlightedText>UN RETOUR RAPIDE</HighlightedText> DES PROFESSIONNELS</h2>
          <p className="flex classic-text mt-7 w-full md:max-w-[450px]">
            Plus de 2000 professionnels issus de 800+ agences et foncières spécialisées se connectent quotidiennement pour vous répondre rapidement.
          </p>
          <TransactionButton 
            className="max-w-full md:max-w-[225px] !mt-8"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          >
            Lancer ma recherche
          </TransactionButton>
        </TitleBlockSecond>
      </CommunityContainer>
    </CommunityWrapper>
  )
})

const StyledTitle = styled.h2`
  color: var(--Neutral-900);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`

const MemoizedBelowContainer = memo(({ images }) => (
  <BelowContainer>
    <TitleRow>
      <StyledTitle>Ils parlent de nous</StyledTitle>
    </TitleRow>
    <MemoizedImageGrid images={images} />
  </BelowContainer>
))

const SectionIndicators = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px 24px 24px;
    width: 100%;
    background: var(--Brand-primary, #0CB2AD);
  }
`

const Indicator = styled.div`
  flex: 1;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 4px;
  position: relative;
  overflow: hidden;

  &:not(:last-child) {
    margin-right: 4px;
  }
`;

const IndicatorProgress = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: white;
  transition: width 0.3s linear;
`;

const sections = [
  {
    id: 'section1',
    title: 'ENVOYEZ VOTRE RECHERCHE',
    desc: "Renseignez rapidement vos critères, grâce à un formulaire conçu par des professionnels spécialisés.",
    img: `${CDN_DOMAIN}/static/v2/section1.png`
  },
  {
    id: 'section2',
    title: 'RECEVEZ DES OFFRES DE QUALITÉ',
    desc: "Notre équipe vérifie chaque proposition selon vos critères avant de vous les transmettre. Fini les alertes mail inutiles.",
    img: `${CDN_DOMAIN}/static/v2/section2.png`
  },
  {
    id: 'section3',
    title: 'RÉPONDEZ EN UN CLIC',
    desc: "Si intéressé, vos coordonnées sont transmises et l‘échange continue en privé, hors plateforme.",
    img: `${CDN_DOMAIN}/static/v2/section3.png`
  }
]

const images = [
  {
    src: 'lefigaro.png',
    alt: 'Le Figaro',
    scale: 1,
    doc: 'figaro.pdf'
  },
  {
    src: 'businessimmo.png',
    alt: 'Business Immo',
    scale: 1,
    doc: 'business_immo.pdf'
  },
  {
    src: 'capital.png',
    alt: 'Capital',
    scale: 1,
    doc: 'capital.pdf'
  },
  {
    src: 'challenges.png',
    alt: 'Challenges',
    scale: 1,
    doc: 'challenges.pdf'
  },
  {
    src: 'forbes.png',
    alt: 'Forbes',
    scale: 1.6,
    doc: 'forbes.pdf'
  },
  {
    src: 'figaroimmo.png',
    alt: 'Figaro Immobilier',
    scale: 1,
    doc: 'figaro_immo.pdf'
  }
]

const icons = [
  { name: 'Dossiers confidentiels échangés', src: `transaction.svg`, stats: '200 000', width: 81 },
  { name: 'Mises en relation par téléphone', src: `mise_en_relation.svg`, stats: '700 000', width: 81 },
  { name: 'Recherches qualifiées envoyées', src: `search.svg`, stats: '100 000', width: 64 },
  { name: 'Sociétés spécialisées (brokers, foncières, institutionnels)', src: `group.svg`, stats: '800', width: 33 },
]

const StepsTitle = styled.h2`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 16px;
    color: var(--color-blue-300);
    font-size: 24px;
    text-align: left;
    padding: 0 20px;
  }
`

const useIntersectionObserver = (options) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  const callback = useCallback((entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(callback, options);
    if (ref.current) observer.observe(ref.current);

    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  }, [ref, options, callback]);

  return [ref, isVisible];
};

const MemoizedImageComponent = memo(({ src, alt, onDragStart }) => {
  const [ref, isVisible] = useIntersectionObserver({
    threshold: 0.1,
    triggerOnce: true
  });

  const handleImageDragStart = (e) => {
    e.preventDefault();
    if (onDragStart) {
      onDragStart(e);
    }
  };

  return (
    <ImageWrapper ref={ref} isVisible={isVisible}>
      <SectionImageBackground />
      <SectionImage>
        <ImageComponent
          src={src}
          alt={alt}
          onDragStart={handleImageDragStart}
          draggable="false"
          className="unselectable"
        />
      </SectionImage>
    </ImageWrapper>
  );
});

const MemoizedSection = memo(React.forwardRef(({ 
  id, 
  title, 
  desc, 
  img, 
  index, 
  active, 
  expandedSections, 
  toggleSectionExpansion 
}, ref) => {
  return (
    <Section 
      id={id}
      active={active}
      ref={ref}
    >
      <SectionContent>
        <SectionNumber>{String(index + 1).padStart(2, '0')}</SectionNumber>
        <SectionTitle>{title}</SectionTitle>
        <SectionDescription expanded>{desc}</SectionDescription>
      </SectionContent>
      <MemoizedImageComponent src={img} alt={title} />
    </Section>
  );
}));

const ContentSectionStyled = memo(styled(ContentSection)`
  @media (max-width: 768px) {
    transition: transform 0.3s ease;
    cursor: ${props => props.isSwiping ? 'grabbing' : 'grab'};
  }
`);

const MemoizedContentContainer = memo(() => {
  const [activeSection, setActiveSection] = useState('');
  const [expandedSections, setExpandedSections] = useState({});
  const sectionRefs = useRef({});
  const tocRef = useRef(null);
  const stickyRef = useRef(null);
  const [swipeOffset, setSwipeOffset] = useState(0);
  const [isSwiping, setIsSwiping] = useState(false);

  const handleScroll = useCallback(() => {
    const scrollPosition = window.scrollY;

    let currentActiveSection = '';
    Object.entries(sectionRefs.current).forEach(([id, ref]) => {
      const rect = ref.getBoundingClientRect();
      if (rect.top <= window.innerHeight / 2 - 64 && rect.bottom >= window.innerHeight / 2 - 64) {
        currentActiveSection = id;
      }
    });
    setActiveSection(currentActiveSection);

    if (tocRef.current && stickyRef.current) {
      const stickyTop = stickyRef.current.offsetTop;
      const shouldStick = scrollPosition >= stickyTop - 64; // 64px avant le haut

      if (shouldStick) {
        const windowHeight = window.innerHeight;
        const tocHeight = tocRef.current.offsetHeight;
        const translate = Math.max(0, (windowHeight - tocHeight) / 2);
        tocRef.current.style.transform = `translateY(${translate}px)`;
      } else {
        tocRef.current.style.transform = 'translateY(0)';
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const scrollToSection = useCallback((id) => {
    const section = sectionRefs.current[id];
    if (section) {
      const yOffset = -64; // Adjust this value to match the sticky header height
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
    }
  }, []);

  const toggleSectionExpansion = useCallback((id) => {
    setExpandedSections(prev => {
      const newState = {
        ...prev,
        [id]: !prev[id]
      };
      
      // Si on passe de non étendu à étendu (c'est-à-dire "Lire plus")
      if (newState[id]) {
        setState(prevState => ({
          ...prevState,
          timeRemaining: 10
        }));
      }
      
      return newState;
    });
  }, []);

  const [state, setState] = useState({
    activeMobileSection: 0,
    timeRemaining: 10,
    isVisible: false  // Nouvel état pour suivre la visibilité de la section
  });

  const sectionRef = useRef(null);  // Nouvelle ref pour la section entière

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(prev => ({ ...prev, isVisible: entry.isIntersecting }));
      },
      { threshold: 0.1 }  // La section est considérée visible quand 10% est visible
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    let timer;
    const checkWidthAndSetTimer = () => {
      if (window.innerWidth <= 768 && state.isVisible) {
        if (!timer) {
          timer = setInterval(() => {
            setState(prevState => {
              if (prevState.timeRemaining === 1) {
                const nextSection = (prevState.activeMobileSection + 1) % sections.length;
                // Ne réinitialiser que les sections étendues autres que la section active
                setExpandedSections(prev => {
                  const newExpandedSections = {...prev};
                  Object.keys(newExpandedSections).forEach(key => {
                    if (key !== sections[nextSection].id) {
                      newExpandedSections[key] = false;
                    }
                  });
                  return newExpandedSections;
                });
                return {
                  ...prevState,
                  activeMobileSection: nextSection,
                  timeRemaining: 10
                };
              }
              return { 
                ...prevState,
                timeRemaining: prevState.timeRemaining - 1 
              };
            });
          }, 1000);
        }
      } else {
        if (timer) {
          clearInterval(timer);
          timer = null;
        }
      }
    };

    // Initial check
    checkWidthAndSetTimer();

    // Add resize event listener
    window.addEventListener('resize', checkWidthAndSetTimer);

    // Cleanup function
    return () => {
      if (timer) clearInterval(timer);
      window.removeEventListener('resize', checkWidthAndSetTimer);
    };
  }, [sections.length, state.isVisible]);  // Ajout de state.isVisible comme dépendance

  const handlers = useSwipeable({
    onSwiping: (eventData) => {
      if (window.innerWidth <= 768) {
        setIsSwiping(true);
        setSwipeOffset(eventData.deltaX);
      }
    },
    onSwipedLeft: (eventData) => {
      if (window.innerWidth <= 768) {
        if (Math.abs(eventData.deltaX) > 50) {
          setState(prevState => ({
            activeMobileSection: (prevState.activeMobileSection + 1) % sections.length,
            timeRemaining: 10
          }));
        }
        setSwipeOffset(0);
        setIsSwiping(false);
      }
    },
    onSwipedRight: (eventData) => {
      if (window.innerWidth <= 768) {
        if (Math.abs(eventData.deltaX) > 50) {
          setState(prevState => ({
            activeMobileSection: (prevState.activeMobileSection - 1 + sections.length) % sections.length,
            timeRemaining: 10
          }));
        }
        setSwipeOffset(0);
        setIsSwiping(false);
      }
    },
    onSwiped: () => {
      setSwipeOffset(0);
      setIsSwiping(false);
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return (
    <ContentContainer ref={sectionRef}>
      <StickyContainer ref={stickyRef}>
        <TableOfContents ref={tocRef}>
          {sections.map(({ id, title }, index) => (
            <TOCItemContainer key={id}>
              <TOCItem onClick={() => scrollToSection(id)}>
                <TOCIcon
                  xmlns="http://www.w3.org/2000/svg" 
                  width="32" 
                  height="32" 
                  viewBox="0 0 32 32" 
                  fill="none"
                  active={activeSection === id}
                >
                  <circle cx="16" cy="16" r="16" fill="#0CB2AD"/>
                  <circle cx="16" cy="16" r="6" fill="#FBFFFF"/>
                </TOCIcon>
                <TOCText active={activeSection === id}>{title}</TOCText>
              </TOCItem>
              {index < sections.length - 1 && <TOCDivider />}
            </TOCItemContainer>
          ))}
        </TableOfContents>
      </StickyContainer>
      <ContentSectionStyled
        swipeOffset={swipeOffset}
        isSwiping={isSwiping}
        {...handlers}
      >
        {sections.map(({ id, title, desc, img }, index) => (
          <MemoizedSection
            key={id}
            id={id}
            title={title}
            desc={desc}
            img={img}
            index={index}
            active={index === state.activeMobileSection}
            expandedSections={expandedSections}
            toggleSectionExpansion={toggleSectionExpansion}
            ref={el => sectionRefs.current[id] = el}
          />
        ))}
        <SectionIndicators>
          {sections.map((_, i) => (
            <Indicator 
              key={i} 
              onClick={() => {
                setState({
                  activeMobileSection: i,
                  timeRemaining: 10
                });
              }}
            >
              {i === state.activeMobileSection && (
                <IndicatorProgress 
                  style={{ width: `${100 - (state.timeRemaining / 10) * 100}%` }} 
                />
              )}
            </Indicator>
          ))}
        </SectionIndicators>
      </ContentSectionStyled>
    </ContentContainer>
  );
});

const InfoReadMoreLink = styled.a`
  color: var(--Neutral-900, #001723);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  display: inline;
  white-space: nowrap;
`;

const HiddenText = styled.span`
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: hidden;
`;

const TransitionParagraph = styled.p`
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
  max-height: ${props => props.isExpanded ? '1000px' : '100px'};
`;

const MemoizedMarketContainer = memo(() => {
  const [isDoorVisible, setIsDoorVisible] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const doorRef = useRef(null);

  const handleIntersection = useCallback((entries) => {
    entries.forEach(entry => {
      setIsDoorVisible(entry.isIntersecting);
    });
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, { threshold: 0.1 });

    const currentRef = doorRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [handleIntersection]);

  const toggleExpand = useCallback(() => {
    setIsExpanded(prevState => !prevState);
  }, []);

  return (
    <MarketContainer>
      <TitleBlock>
        <h2 className="text-[40px] font-extrabold mt-[10px] mb-[10px]">ENTREZ DANS UN MARCHÉ PRIVÉ, <HighlightedText>NON VISIBLE SUR INTERNET</HighlightedText></h2>
        <TransitionParagraph
          isExpanded={isExpanded}
          className="classic-text mt-7 w-full md:!max-w-[450px]"
        >
          Les meilleures opportunités restent confidentielles. Notre plateforme vous connecte avec les professionnels qui détiennent ces produits exclusifs.
        </TransitionParagraph>
      </TitleBlock>
      <BackgroundSVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1178 1230" fill="none" shapeRendering="geometricPrecision">
        <g filter="url(#a)">
          <path fill="url(#b)" fillOpacity=".1" fillRule="evenodd" d="M297.16 900.548c38.456 39.276 65.54 92.633 115.825 114.832 50.348 22.23 112.204-12.64 163.096 8.32 90.212 37.14 145.073 149.3 242.193 158.56 87.522 8.35 180.441-44.19 235.106-113.05 54.83-69.06 32.19-170.773 57.33-255.297 24.86-83.613 100.39-155.738 90.69-242.427-9.7-86.624-102.17-138.633-142.04-216.144-42.7-83.002-18.59-215.091-101.679-257.624-88.641-45.377-190.657 65.466-289.892 57.176-85.021-7.102-157.457-141.89-232.224-100.793-88.186 48.473-18.889 219.6-97.717 282.15-75.508 59.915-247.729-67.126-285.19 21.688-40.223 95.357 137.098 158.467 167.469 257.404 16.67 54.306-52.858 109.367-38.045 164.209 14.674 54.329 75.708 80.785 115.078 120.996Z" clipRule="evenodd"/>
        </g>
        <defs>
          <radialGradient id="b" cx="0" cy="0" r="1" gradientTransform="matrix(-411.44623 -443.83957 511.8171 -474.46247 696.636 579.43)" gradientUnits="userSpaceOnUse">
            <stop stopColor="#fff" stopOpacity="0"/>
            <stop offset="1" stopColor="#00FFC2"/>
          </radialGradient>
          <filter id="a" width="1247.5" height="1228.81" x=".752" y=".322" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur result="effect1_foregroundBlur_201_2843" stdDeviation="23"/>
          </filter>
        </defs>
      </BackgroundSVG>
      <DoorSVG
        ref={doorRef}
        isVisible={isDoorVisible}
        src={`${CDN_DOMAIN}/static/v2/door.svg`} 
        alt="Entrez dans un marché privé, non visible sur internet" 
      />
    </MarketContainer>
  );
});

const MemoizedFranceContainer = memo(() => {
  const [isFranceVisible, setIsFranceVisible] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const franceRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.target.id === 'france') {
            setIsFranceVisible(entry.isIntersecting);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (franceRef.current) observer.observe(franceRef.current);

    return () => {
      if (franceRef.current) observer.unobserve(franceRef.current);
    };
  }, []);

  const toggleExpand = useCallback(() => {
    setIsExpanded(prevState => !prevState);
  }, []);

  return (
    <FranceContainer>
      <BackgroundImage 
        ref={franceRef} 
        id="france" 
        isVisible={isFranceVisible}
        src={`${CDN_DOMAIN}/static/v2/france.svg`} 
        alt="France" 
        className="w-full h-full object-contain"
      />
      <TextBlock>
        <h2 className="text-[40px] font-extrabold mt-[10px] mb-[10px]">INFORMEZ <HighlightedText>100% DU MARCHÉ,</HighlightedText> EN 1 CLIC</h2>
        <TransitionParagraph
          isExpanded={isExpanded}
          className="classic-text mt-7 w-full md:!max-w-[410px]"
        >
          Aucun professionnel ne détient tous les dossiers de son secteur. Diffuser largement votre recherche est crucial pour trouver le bon emplacement.
        </TransitionParagraph>
      </TextBlock>
    </FranceContainer>
  );
});

const AboveTheFold = memo(props => {
  const [selectedType, setSelectedType] = useState(null);
  const [hoveredType, setHoveredType] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [animate, setAnimate] = useState(false);

  const {
    config
  } = props

  const handleSetSelectedType = useCallback((type) => {
    setSelectedType(type);
  }, []);

  const fetchOnlineUsers = useCallback(async () => {
    try {
      const response = await axios.post(
        getConfig().publicRuntimeConfig.externalAPIEndpoint +
        '/api/get_online_users'
      )
      setOnlineUsers(response.data)
      setAnimate(true)

      // Set new timeout
      return setTimeout(() => setAnimate(false), (response.data.length * 100) + 500)
    } catch (error) {
      console.error('Error fetching online users:', error)
    }
  }, [setOnlineUsers, setAnimate])

  useEffect(() => {
    let animationTimeoutId;

    fetchOnlineUsers().then(timeoutId => {
      animationTimeoutId = timeoutId;
    });

    const intervalId = setInterval(fetchOnlineUsers, 20000)

    return () => {
      clearInterval(intervalId)
      if (animationTimeoutId) {
        clearTimeout(animationTimeoutId)
      }
    }
  }, [fetchOnlineUsers])

  const transactions = useTransactions();

  return (
    <MainWrapper>
      <MainContainer>
        <BackgroundBlur />
        <MemoizedLeftContainer selectedType={selectedType} setSelectedType={handleSetSelectedType} setHoveredType={setHoveredType} />
        <PanoramaComponent selectedType={selectedType} hoveredType={hoveredType} bgsMatching={bgsMatching} />
      </MainContainer>
      <MemoizedTrustedByContainer />
      <MemoizedMarketContainer />
      <MemoizedFranceContainer />
      <CommunityContainerMemo onlineUsers={onlineUsers} animate={animate} />
      <div className="relative flex justify-center">
        <GreenGradientBackground />
        <GreenBlockContainer className="flex flex-col">
          <h2 className="text-color-blue-300 text-center text-2xl font-bold uppercase mt-[10px] mb-[10px] md:text-white md:text-5xl md:font-extrabold md:leading-tight md:max-w-[550px] md:ml-8 md:text-left md:normal-case">
            UnEmplacement.com en chiffres
          </h2>
          <MemoizedIconsContainer icons={icons} />
        </GreenBlockContainer>
      </div>
      <StepsTitle>Les étapes de recherche</StepsTitle>
      <MemoizedContentContainer />
      <MemoizedTestimonials />
      <UrgentSearchMemo />
      <div className="relative flex flex-col items-center">
        <MemoizedEventsContainer />
        <TransactionsMemo config={config} transactions={transactions} />
        <MemoizedBelowContainer images={images} />
      </div>
      <PropertyTypeCards />
      <UECFooter config={config} />
    </MainWrapper>
  )
})

export default AboveTheFold