import React, { Component, Fragment } from "react"
import { bindActionCreators } from "redux"
import { NextSeo } from "next-seo"
import { connect } from "react-redux"
import getConfig from "next/config"
import { isMobileOnly } from "react-device-detect"

import CrispBlock from "../components/CrispBlock"
import UECHeader from "../components/v2/UECHeader"
import AboveTheFold from "../components/v2/AboveTheFold"
import { globalsV2 as GlobalStyle } from "../styles"

import {
    setConfig
} from "../actions"

const title = 'L’immobilier d’entreprise off-market : bureaux, locaux commerciaux, entrepôts, fonds de commerce à la location ou à la vente'
const description = 'Accédez à des offres off-market de locaux à louer ou à vendre et découvrez les prix et loyers des bureaux, locaux commerciaux, entrepôts, fonds de commerce, terrains, pop-up stores et espaces de coworking partout en France'

class IndexV2Page extends Component {
    static async getInitialProps({ store, req }) {
        if (req) {
            store.dispatch(setConfig(req.config.publicRuntimeConfig))
        }

        return { isMobileOnly }
    }

    constructor(props) {
        super(props)

        this.state = {
            open: false,
            isMobileOnly: props.isMobileOnly
        }
    }


    handleRequestClose = () => {
        this.setState({
            open: false
        })
    }

    render() {
        return (
            <Fragment>
                <NextSeo
                    titleTemplate="%s - UnEmplacement.com"
                    title={title}
                    description={description}
                    canonical={`https://${this.props.config.websiteDomain}`}
                    openGraph={{
                        url: `https://${
                            this.props.config.websiteDomain
                        }`,
                        title,
                        description
                    }}
                />
                <GlobalStyle />
                <CrispBlock websiteID="5e7d0eae-1770-4c5c-8a0f-951e739365e4" />
                <UECHeader config={this.props.config} hidePriceButton />
                <AboveTheFold config={this.props.config} />
            </Fragment>
        )
    }
}

const mapStateToProps = ({ config }) => {
    return {
        config: {
            ...config.config,
            ...(typeof getConfig() !== "undefined"
                ? getConfig().publicRuntimeConfig
                : {})
        }
    }
}

export default connect(
    mapStateToProps,
    null
)(IndexV2Page)